import PieChart from 'Components/Charts/pieChart'

const PlayerStrokesStats = ({ type, name, graphData }) => {
  const labels = []
  const series = []
  if (graphData) {
    graphData.sort(function (a, b) {
      if (a.key < b.key) {
        return -1
      }
      if (a.key > b.key) {
        return 1
      }

      return 0
    })

    graphData.forEach(function (data) {
      labels.push(data.key)
      series.push(data.value)
    })
  }

  return (
    <div className={'player-strokes-stats ' + type}>
      <div className="strokes-player-name">{name}</div>
      <div className="">
        <PieChart
          clsName={'stoke-stats-chart'}
          labels={labels}
          series={series}
        />
      </div>
    </div>
  )
}

export default PlayerStrokesStats
