import jwtDecode from 'jwt-decode'

export const isLoggedIn = () => {
  const token = localStorage.getItem(`token`)
  if (!token) return false

  const jwtDecoded = jwtDecode(token)
  const expiry = jwtDecoded?.exp
  const timeNow = Math.floor(Date.now() / 1000)
  const status = timeNow < expiry

  return status
}

export const setToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token))
}

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem('token'))

  return token
}

export const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user'))

  return user
}

export const clearStorage = () => {
  localStorage.clear()
}
