import { Modal } from 'antd'

const StatsUnlockModal = ({
  isModalOpen,
  handleCancel,
  handleOk,
  unlockLimit,
}) => {
  return (
    <Modal
      title=""
      open={isModalOpen}
      closable={false}
      footer={null}
      width={'550px'}
    >
      <div className="unlock-video-stats-box">
        <div className="unlock-video-stats-title">Unlock Video Statistics</div>
        <div className="unlock-video-stats-content1">
          {"To see statistics for this video, tap the 'Unlock' button below. You have " +
            (unlockLimit.maxUnlockLimit - unlockLimit.usedUnlockLimit) +
            ' free unlocks left. It may take a few hours to process the statistics.'}
        </div>
        <div className="unlock-video-stats-content2">
          {
            "You'll get useful information like shot type counts, in/out balls, and more. Enjoy!"
          }
        </div>
        <div className="unlock-video-stats-footer">
          <button
            className="unlock-video-stats-cancel-btn"
            onClick={handleCancel}
            disabled={false}
          >
            Cancel
          </button>
          <button
            className="unlock-video-stats-ok-btn"
            onClick={handleOk}
            disabled={false}
          >
            Unlock
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default StatsUnlockModal
