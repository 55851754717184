import { useCallback, useState } from 'react'

export const usePostRequest = (url) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const post = useCallback(
    async ({ header, body }) => {
      const params = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...header },
        body: JSON.stringify(body),
      }

      setLoading(true)
      fetch(url, params)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false)
          setData(data)
          setError(null)
        })
        .catch((error) => {
          setLoading(false)
          setError(error)
          setData(null)
        })
    },
    [url]
  )

  return [post, { data, loading, error }]
}

export const useGetRequest = (url) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const get = useCallback(
    async ({ header }) => {
      const params = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...header },
      }

      setLoading(true)
      fetch(url, params)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false)
          setData(data)
          setError(null)
        })
        .catch((error) => {
          setLoading(false)
          setError(error)
          setData(null)
        })
    },
    [url]
  )

  return [get, { data, loading, error }]
}

export const usePutRequest = (url) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const put = useCallback(
    async ({ header, body }) => {
      const params = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...header },
        body: JSON.stringify(body),
      }

      setLoading(true)
      fetch(url, params)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false)
          setData(data)
          setError(null)
        })
        .catch((error) => {
          setLoading(false)
          setError(error)
          setData(null)
        })
    },
    [url]
  )

  return [put, { data, loading, error }]
}

export const useAjaxFramesGetRequest = (url, path) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const get = useCallback(
    async ({ header }) => {
      const intervalId = setInterval(() => {
        const params = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', ...header },
        }

        setLoading(true)
        fetch(url, params)
          .then((response) => response.json())
          .then((data) => {
            setLoading(false)
            setData(data)
            setError(null)
            if (data.selectedSessionId || window.location.pathname !== path) {
              clearInterval(intervalId)
            }
          })
          .catch((error) => {
            setLoading(false)
            setError(error)
            setData(null)
          })
      }, 15000)
    },
    [url]
  )

  return [get, { data, loading, error }]
}

export const useStatsFramesGetRequest = (url, path, videoId) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const get = useCallback(
    async ({ header }) => {
      const intervalId = setInterval(() => {
        const params = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', ...header },
        }

        setLoading(true)
        fetch(url, params)
          .then((response) => response.json())
          .then((data) => {
            setLoading(false)
            setData(data)
            setError(null)
            if (
              (data && data.isStatsGenerated) ||
              (data && data.status) ||
              (data && data.sessionId !== videoId) ||
              window.location.pathname !== path
            ) {
              clearInterval(intervalId)
            }
          })
          .catch((error) => {
            setLoading(false)
            setError(error)
            setData(null)
          })
      }, 15000)
    },
    [url]
  )

  return [get, { data, loading, error }]
}
