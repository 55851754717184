import errorIcon from 'assets/images/icon_error.svg'
import emptyGraph from 'assets/images/empty_graph.svg'
import VideoLockImage from 'assets/images/background_lock.svg'
import videoProcessing from 'assets/images/video_processing.png'
import 'Components/VideoStatistics/VideoStatistics.css'
import PlayerFrameSelection from 'Components/PlayerFrameSelection'
import { useEffect, useState } from 'react'
import { isLoggedIn, getToken } from 'utils/sessionUtils'
import { dateFormat, getTime } from 'utils/commonUtils'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Layout from 'Components/Layout'
import VideoThumbnail from 'react-video-thumbnail'
import { toast } from 'react-toastify'
import strings from 'data/strings'

import {
  useGetRequest,
  usePostRequest,
  useAjaxFramesGetRequest,
  useStatsFramesGetRequest,
} from 'hooks/useFetch'
import {
  statisticsGetUrl,
  unlockLimitUrl,
  unlockVideoUrl,
  statisticsStatusGetUrl,
} from 'data/urls'
import { Tooltip } from 'antd'
import StrokesIdentification from 'Components/StrokesIdentification'
import VideoFilterView from 'Components/VideoFilterView'
import BallPlacementsMap from 'Components/BallPlacementsMap'
import StatsUnlockModal from 'Components/StatsUnlockModal'

const Statistics = () => {
  const { footer } = strings
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videosList, setVideosList] = useState([])
  const [playersName, setPlayersName] = useState({
    playerOne: null,
    playerTwo: null,
  })
  const [playerFrames, setPlayerFrames] = useState({
    selectedFar: [],
    selectedNear: [],
    far: [],
    near: [],
    submit: false,
    statsReady: false,
    statsFailed: false,
    statsRemarks: '',
  })
  const loggedIn = isLoggedIn()
  const location = useLocation()
  const navigate = useNavigate()
  const { selectedVideoId } = useParams()
  const [selectedVideoData, setSelectedVideoData] = useState({
    id: null,
    name: null,
    url: null,
  })
  const [selectedSessionId, setSelectedSessionId] = useState(
    selectedVideoId || ''
  )
  const [unlockSessionId, setUnlockSessionId] = useState({
    unlockId: null,
    frameId: null,
  })
  const [unlockLimit, setUnlockLimit] = useState({
    maxUnlockLimit: 3,
    usedUnlockLimit: 0,
  })
  const url = statisticsGetUrl.replace('{sessionId}', selectedSessionId)
  const [getProfile, { data, error }] = useGetRequest(url)
  const [getUnlockLimit, { data: limitData }] = useGetRequest(unlockLimitUrl)

  const unlockUrl = unlockSessionId.unlockId
    ? unlockVideoUrl + '?sessionId=' + unlockSessionId.unlockId
    : unlockVideoUrl
  const [postVideoInfo, { data: postData }] = usePostRequest(unlockUrl)
  const frameUrl = statisticsGetUrl.replace(
    '{sessionId}',
    unlockSessionId.frameId
  )
  const [getframes, { data: frameData }] = useAjaxFramesGetRequest(
    frameUrl,
    location.pathname
  )
  const statsUrl = statisticsStatusGetUrl.replace(
    '{sessionId}',
    selectedSessionId
  )
  const [getStatisticsStatus, { data: statisticsStatus }] =
    useGetRequest(statsUrl)
  const [getStatsStatus, { data: statsStatus }] = useStatsFramesGetRequest(
    statsUrl,
    location.pathname,
    selectedSessionId
  )

  useEffect(() => {
    getProfile({
      header: {
        Authorization: getToken(),
      },
    })
  }, [selectedVideoId])

  useEffect(() => {
    getUnlockLimit({
      header: {
        Authorization: getToken(),
      },
    })
  }, [getUnlockLimit])

  useEffect(() => {
    if (limitData) {
      setUnlockLimit({
        maxUnlockLimit: limitData.maxUnlockLimit,
        usedUnlockLimit: limitData.usedUnlockLimit,
      })
    }
  }, [limitData])

  useEffect(() => {
    if (error) {
      console.log({ error })
    } else if (data && data.selectedSessionId) {
      setSelectedSessionId(data.selectedSessionId)
      if (!selectedSessionId) {
        navigate('/statistics/' + data.selectedSessionId)
      }
      const videoData = data.videoData || []
      setVideosList(videoData)
      let names = { playerOne: null, playerTwo: null }
      let statsFailedStatus = false
      let statsFailedMsg = ''
      if (data.videoData?.length > 0) {
        const selectedvideo = data.videoData.filter(
          (item) => item.sessionId === data.selectedSessionId
        )
        names = {
          playerOne: selectedvideo[0].playerOne,
          playerTwo: selectedvideo[0].playerTwo,
        }

        statsFailedStatus = selectedvideo[0].isStatsGenerationFailed
        statsFailedMsg = selectedvideo[0].remarks

        setSelectedVideoData({
          id: selectedvideo[0].sessionId,
          name: selectedvideo[0].fileName,
          url: selectedvideo[0].videoUrl,
        })
      }
      setPlayersName(names)

      const farId = data.far.map((indata) =>
        indata.isSelected ? indata.frameId : null
      )
      const farIds = farId.filter((item) => item !== null)
      const nearId = data.near.map((indata) =>
        indata.isSelected ? indata.frameId : null
      )
      const nearIds = nearId.filter((item) => item !== null)
      setPlayerFrames({
        selectedFar: farIds,
        selectedNear: nearIds,
        submit: farIds.length > 0 || nearIds.length > 0,
        statsReady: false,
        statsFailed: statsFailedStatus,
        statsRemarks: statsFailedMsg,
        far: data.far,
        near: data.near,
      })

      const framesIdData = videoData.filter(
        (video) => video.isFramesGenerated === false && video.isUnlocked
      )
      if (framesIdData.length > 0) {
        setUnlockSessionId((prev) => ({
          ...prev,
          frameId: framesIdData[0].sessionId,
        }))
      }

      if (
        statsFailedStatus !== true &&
        (farIds.length > 0 || nearIds.length > 0)
      ) {
        getStatisticsStatus({
          header: {
            Authorization: getToken(),
          },
        })
      }
    } else if (data) {
      const message = data.message ? data.message : 'Something went wrong'
      toast.warning(message)

      if (videosList?.length > 0) {
        const selectedvideoIndex = videosList.findIndex(
          (item) => item.sessionId === selectedSessionId
        )
        if (selectedvideoIndex && videosList.length > selectedvideoIndex + 1) {
          setSelectedSessionId(videosList[selectedvideoIndex + 1].sessionId)
        } else {
          setSelectedSessionId(videosList[0].sessionId)
        }
      } else {
        navigate('/')
      }
    }
  }, [data])

  useEffect(() => {
    if (!loggedIn) {
      console.log('need to login !!!!')
      navigate('/')
    }
  }, [])

  const selectedVideoUpdate = (videoId, videoUrl) => {
    if (videoUrl) {
      setSelectedSessionId(videoId)
      navigate('/statistics/' + videoId)
    } else {
      toast.info('We are processing your video, please retry after some time')
    }
  }

  const playersNameUpdate = (res) => {
    if (selectedSessionId === res.sessionId) {
      setPlayersName({ playerOne: res.playerOne, playerTwo: res.playerTwo })
    }
  }

  const playerFrameSubmission = (playerFramesStatus) => {
    setPlayerFrames((prev) => ({ ...prev, ...playerFramesStatus }))
  }

  const showModal = (sessionId) => {
    if (unlockLimit.maxUnlockLimit > unlockLimit.usedUnlockLimit) {
      setIsModalOpen(true)
      setUnlockSessionId((prev) => ({ ...prev, unlockId: sessionId }))
    } else {
      toast.error(
        'Your available free unlocks for video statistics have been depleted. For continued access, please reach out to us at ' +
          footer.email +
          '.'
      )
    }
  }

  useEffect(() => {
    if (unlockSessionId.frameId) {
      // call ajax api for video frames check
      getframes({
        header: {
          Authorization: getToken(),
        },
      })
    }
  }, [unlockSessionId.frameId])

  useEffect(() => {
    if (postData && postData.id) {
      toast.success(
        'Unlocked statistics for this video! Your comprehensive insights will be available in a few hours'
      )
      const tableData = videosList.map((val, idx) =>
        val.sessionId === postData.id
          ? { ...val, isUnlocked: true, isFramesGenerated: false }
          : val
      )
      setVideosList(tableData)
      setUnlockLimit((prev) => ({
        ...prev,
        usedUnlockLimit: prev.usedUnlockLimit + 1,
      }))

      const framesIdData = tableData.filter(
        (video) => video.isFramesGenerated === false && video.isUnlocked
      )
      if (framesIdData.length > 0) {
        setUnlockSessionId((prev) => ({ ...prev, frameId: framesIdData[0].id }))
      }
    } else if (postData && postData.message) {
      toast.error(postData.message)
    }
  }, [postData])

  useEffect(() => {
    if (frameData && frameData.selectedSessionId) {
      const tableData = videosList.map((val, idx) =>
        val.sessionId === frameData.selectedSessionId
          ? { ...val, isFramesGenerated: true }
          : val
      )
      setVideosList(tableData)

      const framesIdData = tableData.filter(
        (video) => video.isFramesGenerated === false && video.isUnlocked
      )
      if (framesIdData.length > 0) {
        setUnlockSessionId((prev) => ({ ...prev, frameId: framesIdData[0].id }))
      }
    }
  }, [frameData])

  const handleOk = () => {
    setIsModalOpen(false)

    postVideoInfo({
      header: {
        Authorization: getToken(),
      },
    })
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    const res = statisticsStatus || statsStatus

    if (
      res &&
      playerFrames.submit &&
      !playerFrames.statsReady &&
      res.sessionId === selectedSessionId
    ) {
      if (res.status) {
        setPlayerFrames((prev) => ({ ...prev, statsFailed: true }))
      }
      if (res.isStatsGenerated) {
        setPlayerFrames((prev) => ({ ...prev, statsReady: true }))
      }
    }
  }, [statisticsStatus, statsStatus])

  useEffect(() => {
    if (playerFrames.submit && !playerFrames.statsReady) {
      // call ajax api for video frames check
      getStatsStatus({
        header: {
          Authorization: getToken(),
        },
      })
    }
  }, [playerFrames.submit])

  return (
    <Layout>
      <StatsUnlockModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        unlockLimit={unlockLimit}
      />
      <div className="statistics-contents">
        <div className="statistics-title">Statistics</div>
        <div className="statistics-videos-list">
          {videosList.map((video, index) => (
            <div
              key={index}
              className={
                'statistics-videos-card' +
                (selectedSessionId === video.sessionId ? ' active' : '')
              }
              onClick={
                video.isUnlocked
                  ? () => selectedVideoUpdate(video.sessionId, video.videoUrl)
                  : null
              }
            >
              {video?.isUnlocked && video?.isFramesGenerated === false ? (
                <div className="statistics-video-loader-layout">
                  <div className="statistics-video-loader" />
                </div>
              ) : null}
              {video.videoUrl ? (
                <VideoThumbnail videoUrl={video.videoUrl} />
              ) : (
                <div className="statistics-video-icon-box">
                  <img
                    className="statistics-video-icon"
                    src={videoProcessing}
                    alt="video"
                  />
                </div>
              )}
              <div className="statistics-videos-content">
                <Tooltip placement="topLeft" title={video.fileName}>
                  <div className="statistics-videos-title ellipsis">
                    {video.fileName}
                  </div>
                </Tooltip>
                <div className="statistics-videos-desc ellipsis">
                  {dateFormat(video.createdAt)}
                </div>
                <div className="statistics-videos-desc .ellipsis">
                  Play time:{' '}
                  {video.videoDuration ? getTime(video.videoDuration) : '-'}
                </div>
              </div>
              {!video.isUnlocked ? (
                <div
                  className="statistics-video-lock"
                  onClick={() => showModal(video.sessionId)}
                >
                  <img
                    className="statistics-video-lock-icon"
                    src={VideoLockImage}
                    alt="video"
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>

        <PlayerFrameSelection
          playersName={playersName}
          playersNameUpdate={playersNameUpdate}
          playerFrames={playerFrames}
          selectedSessionId={selectedSessionId}
          playerFrameSubmission={playerFrameSubmission}
        />

        {playerFrames.submit &&
        playerFrames.statsReady &&
        !playerFrames.statsFailed ? (
          <>
            <StrokesIdentification
              selectedSessionId={selectedSessionId}
              playersName={playersName}
              selectedVideoData={selectedVideoData}
            />
            <VideoFilterView
              selectedSessionId={selectedSessionId}
              playersName={playersName}
              selectedVideoData={selectedVideoData}
            />
            <BallPlacementsMap
              playersName={playersName}
              selectedVideoData={selectedVideoData}
            />
          </>
        ) : null}
        {playerFrames.submit &&
        !playerFrames.statsReady &&
        !playerFrames.statsFailed ? (
          <div className="statistics-empty-graph-box">
            <div className="statistics-empty-contents">
              <div className="view-statistics-loader-layout">
                <div className="view-statistics-loader" />
              </div>
              <div className="view-statistics-loader-text">
                <div> We are currently processing the video statistics. </div>
                <div> Kindly visit again after some time. </div>
              </div>
            </div>
          </div>
        ) : null}
        {playerFrames.submit && playerFrames.statsFailed ? (
          <div className="statistics-empty-graph-box">
            <div className="statistics-empty-contents">
              <div className="statistics-empty-contents">
                <img
                  className="statistics-error-icon"
                  src={errorIcon}
                  alt="video"
                />
                <div className="empty-text">
                  <div>
                    {' '}
                    We apologize for the inconvenience, but we are experiencing
                    issues generating statistics for this video.
                  </div>
                  <div>
                    {' '}
                    If you have any questions or need assistance, please reach
                    out to us at <a className="link-color">{footer.email}</a>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {!playerFrames.submit ? (
          <div className="statistics-empty-graph-box">
            <div className="statistics-empty-contents">
              <img
                className="statistics-empty-graph-icon"
                src={emptyGraph}
                alt="video"
              />
              <div className="empty-text">
                Choose a player to view his statistics
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  )
}

export default Statistics
