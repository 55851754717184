import 'Components/StrokesIdentification/StrokesIdentification.css'
import { Tabs } from 'antd'
import PlayerStrokesStats from './PlayerStrokesStats'
import { useGetRequest } from 'hooks/useFetch'
import { updatePlayerAttributesUrl } from 'data/urls'
import { useEffect, useState } from 'react'
import { getToken } from 'utils/sessionUtils'
const { TabPane } = Tabs

const StrokesIdentification = ({ playersName, selectedVideoData }) => {
  const [filterType, setFilterType] = useState('ALL')
  const [playerData, setPlayerData] = useState({ p1: null, p2: null })
  const updateSessionIdUrl = updatePlayerAttributesUrl.replace(
    '{sessionId}',
    selectedVideoData.id
  )
  const url =
    filterType !== 'ALL'
      ? updateSessionIdUrl + '?filterType=' + filterType
      : updateSessionIdUrl
  const [getProfile, { data, error }] = useGetRequest(url)

  useEffect(() => {
    getProfile({
      header: {
        Authorization: getToken(),
      },
    })
    setPlayerData({ p1: null, p2: null })
  }, [getProfile])

  useEffect(() => {
    if (error) {
      console.log({ error })
    } else if (data && data.sessionId) {
      setPlayerData({ p1: data.p1, p2: data.p2 })
    }
  }, [data, error])

  const tabsChanges = (key) => {
    setFilterType(key)
  }

  return (
    <div className="strokes-identification-section">
      <div className="strokes-identification-header">
        <div className="strokes-identification-title ellipsis">
          {selectedVideoData.name}
        </div>
        <div className="strokes-identification-view-option">
          {/* <Radio.Group defaultValue="1">
            <Radio.Button value="1">Charts</Radio.Button>
            <Radio.Button value="2">Table</Radio.Button>
          </Radio.Group> */}
        </div>
      </div>
      <Tabs
        className="strokes-identification-tab"
        defaultActiveKey="1"
        onChange={tabsChanges}
      >
        <TabPane tab="All" key="ALL" className="player-strokes-tabs">
          <PlayerStrokesStats
            type="player1"
            name={playersName.playerOne}
            graphData={playerData.p1}
          />
          <PlayerStrokesStats
            type="player2"
            name={playersName.playerTwo}
            graphData={playerData.p2}
          />
        </TabPane>
        <TabPane tab="Forehand" key="FOREHAND" className="player-strokes-tabs">
          <PlayerStrokesStats
            type="player1"
            name={playersName.playerOne}
            graphData={playerData.p1}
          />
          <PlayerStrokesStats
            type="player2"
            name={playersName.playerTwo}
            graphData={playerData.p2}
          />
        </TabPane>
        <TabPane tab="Backhand" key="BACKHAND" className="player-strokes-tabs">
          <PlayerStrokesStats
            type="player1"
            name={playersName.playerOne}
            graphData={playerData.p1}
          />
          <PlayerStrokesStats
            type="player2"
            name={playersName.playerTwo}
            graphData={playerData.p2}
          />
        </TabPane>
        <TabPane tab="Services" key="SERVICE" className="player-strokes-tabs">
          <PlayerStrokesStats
            type="player1"
            name={playersName.playerOne}
            graphData={playerData.p1}
          />
          <PlayerStrokesStats
            type="player2"
            name={playersName.playerTwo}
            graphData={playerData.p2}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default StrokesIdentification
