import { Table, Tooltip } from 'antd'
import iconDownload from 'assets/images/icon_download.svg'
import iconCheck from 'assets/images/icon_check.svg'
import iconProgress from 'assets/images/icon_hourglass.svg'
import iconFailed from 'assets/images/icon_error.svg'
import iconCancel from 'assets/images/icon_cancel.svg'
import iconLink from 'assets/images/icon_link.svg'
import statisticsGraph from 'assets/images/view_statistics.svg'
import statisticsGraphLock from 'assets/images/view_statistics_lock.svg'
import { Link } from 'react-router-dom'
import { dateFormat, getTime } from 'utils/commonUtils'

const VideosDataTable = (props) => {
  const downloadFile = (rowContent) => {
    const link = document.createElement('a')
    link.download = rowContent.fileName
    link.href = rowContent.processedFileUrl
    link.click()
  }

  const statsProgressBar = {
    width: `${props.progress}%`,
    height: '100%',
    background:
      'repeating-linear-gradient(75deg, rgb(85, 133, 255), rgb(85, 133, 255) 11px, rgba(85, 133, 255, 0.625) 10px, rgba(85, 133, 255, 0.625) 25px)',
    position: 'absolute',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  }

  const status = (rowContent) => {
    switch (rowContent.uploadStatus) {
      case 'COMPLETED':
        return (
          <div className="stats-status-layout stats-status-completed">
            <img className="stats-status-icon" src={iconCheck} alt="check" />
            <p className="stats-upload-status stats-completed-text">
              Completed
            </p>
          </div>
        )
      case 'IN_PROGRESS':
        return (
          <div className="stats-status-layout stats-status-inprogress">
            <img className="stats-status-icon" src={iconProgress} alt="check" />
            <p className="stats-upload-status stats-inprogress-text">
              In progress
            </p>
          </div>
        )
      case 'FAILED':
        return (
          <div className="stats-status-layout stats-status-failed">
            <img className="stats-status-icon" src={iconFailed} alt="check" />
            <p className="stats-upload-status stats-failed-text">Failed</p>
          </div>
        )
      default:
        return null
    }
  }

  const processFileData = (rowContent) => {
    if (
      rowContent.processedFileUrl !== null &&
      rowContent.status !== 'Uploading'
    ) {
      return (
        <div className="stats-file-layout">
          <div className="stats-processed-video-duration">
            <div className="stats-play-time-duration">
              {getTime(rowContent.outputVideoDuration)}
            </div>
            <img
              className="stats-link-icon"
              src={iconLink}
              onClick={() => props.copy(rowContent)}
              alt="link"
            />
          </div>
        </div>
      )
    } else if (
      rowContent.processedFileUrl === null &&
      rowContent.uploadStatus !== 'FAILED'
    ) {
      return (
        <div className="stats-file-layout">
          <p className="stats-processed-video-duration">
            We will notify you via email in few hours,
          </p>
          <p className="stats-processed-video-duration">
            {' '}
            when the video is ready
          </p>
        </div>
      )
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'serialNo',
      width: '70px',
      ellipsis: true,
      align: 'center',
      className: 'serial-no',
      render: (text, record, index) => (
        <div key={index} className="stats-sl-no">
          {parseInt(index) + 1}.
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      sorter: false,
      width: '40%',
      minWidth: '200px',
      align: 'left',
      ellipsis: true,
      className: 'status-table-td',
      render: (text, record, index) => (
        <div key={index} className="stats-name-layout">
          {record.status === 'Uploading' ? (
            <>
              <Tooltip
                placement="topLeft"
                overlayClassName=""
                title={record.name ? record.name : null}
              >
                <p className="stats-video-name ellipsis">{record.name}</p>
              </Tooltip>
              <p className="stats-video-duration">{record.sub}</p>
            </>
          ) : (
            <>
              <Tooltip
                placement="topLeft"
                overlayClassName=""
                title={record.fileName ? record.fileName : null}
              >
                <p className="stats-video-name ellipsis">{record.fileName}</p>
              </Tooltip>
              <p className="stats-video-duration">
                {record.inputVideoDuration
                  ? getTime(record.inputVideoDuration)
                  : null}
              </p>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Upload Date',
      dataIndex: 'UploadDate',
      sorter: false,
      align: 'left',
      width: '120px',
      ellipsis: true,
      render: (text, record, index) => (
        <div key={index} className="stats-upload-date">
          {dateFormat(record.createdAt)}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      width: '140px',
      align: 'left',
      sorter: false,
      ellipsis: true,
      render: (text, record, index) =>
        record.status === 'Uploading' ? (
          <div className="stats-progress-layout">
            <div style={statsProgressBar}>
              <p className="stats-progress-hidden">{props.progress}%</p>
            </div>
            <p className="stats-progress">{props.progress}%</p>
          </div>
        ) : (
          <div key={index} className="text-truncate">
            {status(record)}
          </div>
        ),
    },
    {
      title: 'Play Time Video',
      dataIndex: 'PlayTimeVideo',
      width: '250px',
      align: 'left',
      sorter: false,
      ellipsis: true,
      render: (text, record, index) => (
        <div key={index} className="play-time-td">
          {processFileData(record)}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'download',
      width: '140px',
      sorter: false,
      ellipsis: true,
      className: 'action-col',
      render: (text, record, index) => (
        <div key={index} className="stats-action-box">
          {record.status === 'Uploading' ? (
            <img
              className="stats-cancel-icon"
              src={iconCancel}
              alt="download"
              onClick={props.abortUpload}
            />
          ) : null}
          {record.status !== 'Uploading' && record.processedFileUrl ? (
            <>
              <img
                className="stats-download-icon"
                src={iconDownload}
                alt="download"
                onClick={() => downloadFile(record)}
              />
              {/* {index < 20 ? ( */}
              <div
                className={
                  'statistics-icon-layout' +
                  (record.isUnlocked ? ' icon-left' : '')
                }
              >
                {record?.isFramesGenerated === false && record.isUnlocked ? (
                  <div className="statistics-loader-layout">
                    <div className="statistics-loader" />
                  </div>
                ) : null}

                {record.isUnlocked ? (
                  <Link
                    to={'/statistics/' + record.id}
                    className="header-back-btn"
                  >
                    <img
                      className="view-statistics-icon"
                      src={statisticsGraph}
                      alt="graph"
                    />
                  </Link>
                ) : (
                  <img
                    className="view-statistics-lock-icon"
                    src={statisticsGraphLock}
                    alt="graph"
                    onClick={() => props.showModal(record.id)}
                  />
                )}
              </div>
            </>
          ) : null}
        </div>
      ),
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={props.data}
        scroll={{ x: 850, y: 678 }}
        rowKey={(record) => record.id}
        className="mt-4 video-table"
        pagination={false}
      />
    </>
  )
}
export default VideosDataTable
