import ReactApexChart from 'react-apexcharts'

const PieChart = ({ series, labels, clsName }) => {
  const options = {
    chart: {
      type: 'donut',
    },
    legend: {
      position: 'bottom',
      fontSize: '18px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      labels: {},
      markers: {
        width: 24,
        height: 24,
        radius: 4,
        offsetX: -5,
      },
      itemMargin: {
        horizontal: 18,
        vertical: 0,
      },
    },
    colors: ['#466633', '#8F9DBE', '#C6D855'],
    labels,
    plotOptions: {
      pie: {
        startAngle: 360,
        endAngle: 0,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: '50%',
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  }

  return (
    <div id="chart" className={clsName}>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={'100%'}
      />
    </div>
  )
}

export default PieChart
