const strings = {
  header: {
    about: 'About',
    features: 'Features',
    guidelines: 'Guidelines',
    contact: 'Contact',
    login: 'Login/ Signup',
    logout: 'Logout',
  },
  banner: {
    head1: 'Elevate your Tennis',
    head2: 'game using AI.',
    subtext1:
      'Upload your recorded Tennis game, our AI removes the non-play time- ',
    subtext2: 'for FREE!!',
    login: 'Login/ Signup',
  },
  about: {
    title: 'About',
    jusplay: 'Jusplay',
    para1:
      'At Jusplay, we are on a mission to help Tennis players and coaches with technology.',
    para2: 'Using AI, we generate game statistics from a Tennis game video.',
  },
  features: {
    our: 'Our',
    title: 'Features',
    feat1: 'Dead time (non-play time) removal',
    feat2: 'Game statistics generation (Coming Soon)',
    feat3: 'Video highlights of Rallies (Coming Soon)',
  },
  guidelines: {
    title: 'Guidelines for',
    record: 'recording',
    choices: 'Equipment choices',
    choice1: 'Fence mount',
    choice2: 'Chain link mount',
    choice3: 'tripod',
    height: 'Height of the camera',
    recording: 'Recording should be done 10ft above from the ground.',
    captureTitle: 'Camera view',
    captureContent:
      'Make sure you capture an optimal view as shown in the figure.',
  },
  footer: {
    title: 'Jusplay',
    contact: 'Contact',
    phone: '+91-9110342830',
    email: 'contact@jusplay.net',
    org: '© NicheSolv',
  },
  selectfile: {
    progress: 'Video is uploading....',
    note: "Please don't close this web page",
    uploading: 'Uploading',
    soon: 'We will notify you via email in few hours, when the video is ready',
    drop: 'Drop file here or click ',
    files: 'Select files',
    instruct1: 'Instruction to upload',
    ensure: '1. Ensure you select a single video file and not a folder.',
    or: 'Or',
    link: 'Provide link to the file',
    send: 'Send',
    instruct2: 'Instruction to the link',
    single:
      '1. The link should be pointing to a single video file in your google drive, and not the folder.',
    grant:
      "2. Please grant 'anyone with the link' access to the  video in Google Drive.",
  },
  signin: {
    title: 'Sign In',
    google: 'Continue with Google',
    fb: 'Continue with Facebook',
  },
  profile: {
    nameError: 'Please enter a user name',
    emailError: 'Please enter a valid email',
    phoneError: 'Please enter a valid phone number',
    detail: 'Profile details',
    name: 'User name',
    email: 'Email ID',
    phone: 'Phone number',
    note: 'We will not share your contacts with anyone, will only contact you if necessary',
    submit: 'Submit',
  },
  instruct: {
    upload: 'Upload new video',
    criteria: 'Video criteria',
    ticks: 'Check if your video ticks the following requirements',
    quality: 'Quality of video',
    accept: 'We accept 720p and 1080p',
    size: 'Size of the video',
    limit: 'Video should be less than 5GB',
    format: 'Format of the video',
    type: 'mp4, mov, avi or mkv',
    record: 'Recording quality',
    guidelines: 'Please check the recording guidelines.',
    videos: 'Upload your Videos',
    drive:
      'Please click the "Upload" button or provide us with a Google Drive link. You can delete any video you no longer want',
    notify: 'Get notified when done',
    email: 'An email notification will be sent when the video is ready',
  },
  game: {
    details: 'Please give the following details of the video',
    upload: 'Uploaded by',
    player: 'Player',
    coach: 'Coach',
    other: 'Others',
    type: 'The game was a',
    practice: 'Practice',
    tournament: 'Tournament',
    locale: 'Game was played in',
    country: 'Country',
    state: 'State',
    submit: 'Submit',
    optionError: 'Please choose an option',
    descError: 'Please enter a description',
    regionError: 'Please select a country and state',
  },
}

export default strings
