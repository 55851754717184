import './SignInModal.css'
import facebookLogo from 'assets/images/icon_facebook.svg'
import googleLogo from 'assets/images/icon_google.svg'
import iconClose from 'assets/images/icon_close.svg'
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'

import Modal from 'react-modal'
import { MODAL_TYPES, useModalContext } from 'Components/ModalContext'
import { getUser, setToken, setUser } from 'utils/sessionUtils'
import { usePostRequest } from 'hooks/useFetch'
import { useEffect } from 'react'
import { loginUrl } from 'data/urls'
import strings from 'data/strings'
import axios from 'axios'

const customStyles = {
  content: {
    margin: 'auto',
    border: 'none',
    background: 'transparent',
    display: 'flex',
    inset: '0',
  },
  overlay: {
    backgroundColor: '#00000099',
  },
}

const SignInModal = (props) => {
  Modal.setAppElement('#root')

  const { signin } = strings
  const { showModal, hideModal } = useModalContext()
  const [login, { data, error }] = usePostRequest(loginUrl)

  useEffect(() => {
    if (error) {
      console.log({ error })
    } else if (data) {
      const { message, newAccount, jwt, uuid, phoneNumber } = data
      if (message) {
        showModal(MODAL_TYPES.ERROR_INFO_MODAL, { message })

        return
      }

      setToken(jwt)
      setUser({ ...getUser(), uuid, phoneNumber })
      if (newAccount) {
        showModal(MODAL_TYPES.UPDATE_PROFILE_MODAL, {})
      } else {
        window.location.reload()
      }
    }
  }, [error, data, showModal])

  const handleGoogleLogin = ({ provider, data }) => {
    const user = {
      name: data.name,
      email: data.email,
      picture: data.picture,
      providerToken: data.access_token,
      expiry: data.expires_in,
      provider,
    }
    setUser(user)
    const date = new Date()
    date.setSeconds(date.getSeconds() + data.expires_in)
    login({
      header: {
        accesstoken: data.access_token,
        provider: provider.toUpperCase(),
        expirytime: date.getTime(),
      },
    })
  }

  const handleGoogleReject = (err) => {
    console.log({ err })
  }

  const handleFbLogin = async ({ provider, data }) => {
    const user = {
      name: data.name,
      email: data.email,
      picture: data.picture.data.url,
      providerToken: data.accessToken,
      expiry: data.expiresIn,
      provider,
    }
    const date = new Date()
    date.setSeconds(date.getSeconds() + data.expiresIn)
    const response = await axios.get(
      `https://graph.facebook.com/v12.0/${data.id}/picture?redirect=false&type=large&access_token=${data.accessToken}`
    )
    const imgUrl = response?.data?.data?.url ? response.data.data.url : null
    setUser({ ...user, picture: imgUrl })
    login({
      header: {
        accesstoken: data.accessToken,
        provider: provider.toUpperCase(),
        expirytime: date.getTime(),
      },
    })
  }

  const handleFbReject = (err) => {
    console.log({ err })
  }

  return (
    <Modal isOpen onRequestClose={hideModal} style={customStyles}>
      <div className="login-modal-content">
        <p className="modal-header">{signin.title}</p>
        <img
          className="modal-icon-close"
          src={iconClose}
          onClick={hideModal}
          alt="close"
        />
        <LoginSocialGoogle
          scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
          client_id={process.env.REACT_APP_GOOGLE_APP_ID}
          onResolve={handleGoogleLogin}
          onReject={handleGoogleReject}
        >
          <div className="modal-social-layout google">
            <img src={googleLogo} alt="google" className="icon-google" />
            <p className="modal-label-google">{signin.google}</p>
          </div>
        </LoginSocialGoogle>

        <LoginSocialFacebook
          appId={process.env.REACT_APP_FB_APP_ID}
          fieldsProfile={'id,name,picture,email'}
          onResolve={handleFbLogin}
          onReject={handleFbReject}
        >
          <div className="modal-social-layout facebook">
            <img src={facebookLogo} alt="facebook" className="icon-facebook" />
            <p className="modal-label-facebook">{signin.fb}</p>
          </div>
        </LoginSocialFacebook>
      </div>
    </Modal>
  )
}

export default SignInModal
