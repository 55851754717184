import 'Components/Footer/Footer.css'
import logo from 'assets/images/icon_logo.svg'
import iconCall from 'assets/images/icon_call.svg'
import iconMail from 'assets/images/icon_mail.svg'
import strings from 'data/strings'

function Footer() {
  const { footer } = strings

  return (
    <div className="footer-layout" id="footer">
      <div className="footer-contents">
        <div className="footer-section-one footer-row">
          <div className="footer-main footer-row">
            <img className="footer-logo" src={logo} alt="logo" />
            <p className="footer-title">{footer.title}</p>
          </div>
          {/* <div className='column'>
            <p className='footer-tab-name'>Jusplay</p>
            <p className='footer-tab-item'>Home</p>
            <p className='footer-tab-item'>Features</p>
            <p className='footer-tab-item'>Highlights</p>
            <p className='footer-tab-item'>Login</p>
          </div>
          <div className='column'>
            <p className='footer-tab-name'>Connect</p>
            <p className='footer-tab-item'>Subscribe</p>
          </div> */}
          <div className="footer-column">
            <p className="footer-tab-name">{footer.contact}</p>
            <div className="footer-contact-layout footer-row">
              <img src={iconCall} alt="call" />
              <p className="footer-tab-item footer-contact">{footer.phone}</p>
            </div>
            <div className="footer-row">
              <img src={iconMail} alt="mail" />
              <p className="footer-tab-item footer-contact">{footer.email}</p>
            </div>
          </div>
        </div>
        <div className="footer-section-two footer-column">
          <p className="footer-org-name">{footer.org}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
