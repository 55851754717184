import 'Components/BallPlacementsMap/BallPlacementsMap.css'
import { Col, Row } from 'antd'
import shoePrintIcon from 'assets/images/shoe_print_icon.svg'
import BallNetIcon from 'assets/images/ball_net.svg'
import { useState, useRef, useEffect } from 'react'
import { useGetRequest } from 'hooks/useFetch'
import { ballPositionStatGetUrl } from 'data/urls'
import { getToken } from 'utils/sessionUtils'
import PlayGround from 'Components/Canvas/playGround'
import BallPlayerPositionFilter from './BallPlayerPositionFilter'

const BallPlacementsMap = ({ playersName, selectedVideoData }) => {
  const parentRef = useRef(null)
  const parentRef2 = useRef(null)
  const [positionsData, setPositionsData] = useState({
    player1All: [],
    player2All: [],
    p1: [],
    p2: [],
  })

  const initUrl =
    ballPositionStatGetUrl
      .replace('{sessionId}', selectedVideoData.id)
      .toString() + ''
  const [getBallPlacementData, { data, error }] = useGetRequest(initUrl)

  useEffect(() => {
    getBallPlacementData({
      header: {
        Authorization: getToken(),
      },
    })
  }, [getBallPlacementData])

  const PositionsUpdate = (player, strokeType, ballInOut) => {
    const allpositions =
      positionsData[player === 'p1' ? 'player1All' : 'player2All']
    const filterData = allpositions.filter(
      (person) =>
        strokeType.includes(person.strokeType) &&
        ballInOut.includes(person.ballInOut)
    )
    setPositionsData((prev) => ({ ...prev, [player]: filterData }))
  }

  useEffect(() => {
    if (data) {
      setPositionsData({
        player1All: data.p1,
        player2All: data.p2,
        p1: data.p1,
        p2: data.p2,
      })
    }
  }, [data, error])

  return (
    <div className="ball-placement-map-page">
      <div className="ball-placement-map-header">
        <div className="ball-placement-map-title">Map of placements:</div>
        <div className="">
          {/* <Radio.Group defaultValue="1">
            <Radio.Button value="1">Ball Positions</Radio.Button>
            <Radio.Button value="2">Player Positions</Radio.Button>
          </Radio.Group> */}
        </div>
      </div>

      <Row className="ball-placement-ground">
        <Row className="ball-placement-ground-row one">
          <div className="ball-placement-filter-box">
            <div className="ball-placement-player-name">
              {playersName.playerOne}
            </div>
            <div className="ball-placement-filter-options">
              <BallPlayerPositionFilter
                PositionsUpdate={PositionsUpdate}
                player={'p1'}
              />
            </div>
          </div>
          <div className="far-court-name">Player positions</div>
          <Col className="ball-placement-ground-col">
            <div ref={parentRef} className="ball-placement-ground-1">
              <PlayGround parentRef={parentRef} positions={positionsData.p1} />
            </div>
          </Col>
          <div className="near-court-name">Ball positions</div>
        </Row>
        <Row className="ball-placement-ground-row second">
          <div className="ball-placement-filter-box">
            <div className="ball-placement-player-name">
              {playersName.playerTwo}
            </div>
            <div className="ball-placement-filter-options">
              <BallPlayerPositionFilter
                PositionsUpdate={PositionsUpdate}
                player={'p2'}
              />
            </div>
          </div>
          <div className="far-court-name">Ball positions</div>
          <Col className="ball-placement-ground-col">
            <div ref={parentRef2} className="ball-placement-ground-2">
              <PlayGround parentRef={parentRef2} positions={positionsData.p2} />
            </div>
          </Col>
          <div className="near-court-name">Player positions</div>
        </Row>
      </Row>

      <div className="ball-placement-ground-legend-box">
        <div className="ball-placement-ground-legend-title">Filter</div>

        <div className="ball-placement-ground-legend-row">
          <div className="ball-placement-ground-legend-col">
            <div className="ball-placement-ground-legend-li">
              <span className="dot green">{''}</span>
              <div className="ball-placement-ground-legend-name">Forehand</div>
            </div>
            <div className="ball-placement-ground-legend-li">
              <span className="dot yellow">{''}</span>
              <div className="ball-placement-ground-legend-name">Backhand</div>
            </div>
            <div className="ball-placement-ground-legend-li">
              <span className="dot blue">{''}</span>
              <div className="ball-placement-ground-legend-name">Service</div>
            </div>
            <div className="ball-placement-ground-legend-li">
              <img src={shoePrintIcon} width={24} height={24} />
              <div className="ball-placement-ground-legend-name">Player</div>
            </div>
          </div>
          <div className="ball-placement-ground-legend-col">
            <div className="ball-placement-ground-legend-li">
              <span className="dot white">{''}</span>
              <div className="ball-placement-ground-legend-name">Out</div>
            </div>
            <div className="ball-placement-ground-legend-li">
              <span className="dot black">{''}</span>
              <div className="ball-placement-ground-legend-name">In</div>
            </div>
            <div className="ball-placement-ground-legend-li">
              <img src={BallNetIcon} width={24} height={24} />
              <div className="ball-placement-ground-legend-name">Net</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BallPlacementsMap
