import './VideoInfoModal.css'
import iconClose from 'assets/images/icon_close.svg'
import Modal from 'react-modal'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useRef, useState } from 'react'
import strings from 'data/strings'

const customStyles = {
  content: {
    height: '100%',
    margin: 'auto',
    border: 'none',
    background: 'transparent',
    display: 'flex',
    inset: 0,
  },
  overlay: {
    backgroundColor: '#00000099',
  },
}

const VideoInfoModal = (props) => {
  Modal.setAppElement('#root')

  const { game } = strings
  const { onSubmit, hideModal, loading } = props
  const [country, setCountry] = useState()
  const [region, setRegion] = useState()
  const [error, setError] = useState()
  const descRef1 = useRef()
  const descRef2 = useRef()
  const [formState, setFormState] = useState({
    player: false,
    coach: false,
    others1: false,
    others1Desc: '',
    practice: false,
    tournament: false,
    others2: false,
    others2Desc: '',
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    let error
    if (!formState.player && !formState.coach && !formState.others1) {
      error = { ...error, error1: game.optionError }
    }
    if (formState.others1 && descRef1.current.value === '') {
      error = { ...error, error1: game.descError }
    }
    if (!formState.practice && !formState.tournament && !formState.others2) {
      error = { ...error, error2: game.optionError }
    }
    if (formState.others2 && descRef2.current.value === '') {
      error = { ...error, error2: game.descError }
    }
    if (!country || !region) {
      error = { ...error, error3: game.regionError }
    }
    setError(error)
    if (error) {
      return
    }
    const formData = {}
    if (formState.player) {
      formData.uploadedBy = 'player'
    } else if (formState.coach) {
      formData.uploadedBy = 'coach'
    } else {
      formData.uploadedBy = descRef1.current.value
    }
    if (formState.practice) {
      formData.gameType = 'practice'
    } else if (formState.tournament) {
      formData.gameType = 'tournament'
    } else {
      formData.gameType = descRef2.current.value
    }
    formData.country = country
    formData.state = region
    onSubmit(formData)
  }

  const handleInputChange = (e) => {
    const name = e.target.name
    let state = formState
    if (['player', 'coach', 'others1'].includes(name)) {
      state = {
        ...state,
        player: false,
        coach: false,
        others1: false,
        [name]: e.target.checked,
      }
    } else if (['practice', 'tournament', 'others2'].includes(name)) {
      state = {
        ...state,
        practice: false,
        tournament: false,
        others2: false,
        [name]: e.target.checked,
      }
    }
    setFormState(state)
  }

  return (
    <Modal isOpen style={customStyles}>
      <form className="vi-layout" onSubmit={handleSubmit}>
        <div className="vi-header-layout">
          <p className="vi-header">{game.details}</p>
          <img
            className="vi-icon-close"
            src={iconClose}
            onClick={hideModal}
            alt="close"
          />
        </div>
        <div className="vi-input-layout">
          <p className="vi-category-name">
            {game.upload} <span className="vi-asterisk">*</span>
          </p>
          <div className="vi-checkbox-layout">
            <input
              name="player"
              type="checkbox"
              onChange={handleInputChange}
              checked={formState.player}
            />
            <p className="vi-input-name">{game.player} </p>
          </div>
          <div className="vi-checkbox-layout">
            <input
              name="coach"
              type="checkbox"
              onChange={handleInputChange}
              checked={formState.coach}
            />
            <p className="vi-input-name">{game.coach} </p>
          </div>
          <div />
          <div className="vi-checkbox-layout">
            <input
              name="others1"
              type="checkbox"
              onChange={handleInputChange}
              checked={formState.others1}
            />
            <p className="vi-input-name">{game.other}</p>
          </div>
          <input className="vi-input-others" type="text" ref={descRef1} />
        </div>
        {error?.error1 && <p className="vi-error">{error.error1}</p>}
        <div className="vi-input-layout">
          <p className="vi-category-name">
            {game.type} <span className="vi-asterisk">*</span>
          </p>
          <div className="vi-checkbox-layout">
            <input
              name="practice"
              type="checkbox"
              onChange={handleInputChange}
              checked={formState.practice}
            />
            <p className="vi-input-name">{game.practice} </p>
          </div>
          <div className="vi-checkbox-layout">
            <input
              name="tournament"
              type="checkbox"
              onChange={handleInputChange}
              checked={formState.tournament}
            />
            <p className="vi-input-name">{game.tournament} </p>
          </div>
          <div />
          <div className="vi-checkbox-layout">
            <input
              name="others2"
              type="checkbox"
              onChange={handleInputChange}
              checked={formState.others2}
            />
            <p className="vi-input-name">{game.other}</p>
          </div>
          <input className="vi-input-others" type="text" ref={descRef2} />
        </div>
        {error?.error2 && <p className="vi-error">{error.error2}</p>}
        <div className="vi-input-layout">
          <p className="vi-category-name">
            {game.locale} <span className="vi-asterisk">*</span>
          </p>
          <p className="vi-input-name">
            {game.country} <span className="vi-asterisk">*</span>
          </p>
          <CountryDropdown
            className="vi-input-select"
            value={country}
            onChange={(val) => setCountry(val)}
          />
          <div />
          <p className="vi-input-name rg-input-name">
            {game.state} <span className="vi-asterisk">*</span>
          </p>
          <RegionDropdown
            className="vi-input-select rg-select"
            country={country}
            value={region}
            onChange={(val) => setRegion(val)}
          />
        </div>
        {error?.error3 && <p className="vi-error">{error.error3}</p>}
        {loading && (
          <div className="vi-loader-layout">
            <div className="vi-loader" />
          </div>
        )}
        {!loading && (
          <button className="vi-submit" disabled={false} type="submit">
            {game.submit}
          </button>
        )}
      </form>
    </Modal>
  )
}

export default VideoInfoModal
