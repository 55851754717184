import 'Components/OurFeatures/OurFeatures.css'
import iconOne from 'assets/images/feature-icon-one.svg'
import iconTwo from 'assets/images/feature-icon-two.svg'
import iconThree from 'assets/images/feature-icon-three.svg'
import strings from 'data/strings'

function OurFeatures() {
  const { features } = strings

  return (
    <div className="of-layout" id="features">
      <div className="of-content">
        <p className="of-head">
          {features.our}{' '}
          <span className="of-head-orange">{features.title}</span>
        </p>
        <div className="of-items-layout">
          <div className="of-item-layout">
            <img className="of-item-icon" src={iconThree} />
            <p className="of-item-title">{features.feat1}</p>
            {/* <p className='of-item-desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor sed eget.</p> */}
          </div>
          <div className="of-item-layout">
            <img className="of-item-icon" src={iconTwo} />
            <p className="of-item-title">{features.feat2}</p>
            {/* <p className='of-item-desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor sed eget.</p> */}
          </div>
          <div className="of-item-layout">
            <img className="of-item-icon" src={iconOne} />
            <p className="of-item-title">{features.feat3}</p>
            {/* <p className='of-item-desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor sed eget.</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurFeatures
