import 'Components/Header/Header.css'
import logo from 'assets/images/icon_logo.svg'
import backArrow from 'assets/images/back_arrow.svg'
import iconHamburger from 'assets/images/icon_hamburger.png'
import { MODAL_TYPES, useModalContext } from 'Components/ModalContext'
import { getUser, isLoggedIn } from 'utils/sessionUtils'
import strings from 'data/strings'
import { Link, useLocation } from 'react-router-dom'

function Header(props) {
  const { openSideNav } = props
  const { showModal } = useModalContext()
  const user = getUser()
  const loggedIn = isLoggedIn()
  const { header } = strings
  const location = useLocation()
  const isStatisticsPage = location.pathname.startsWith('/statistics')

  const handleShow = () => {
    showModal(MODAL_TYPES.LOGIN_MODAL, {})
  }

  return (
    <div className={isStatisticsPage ? 'header-layout-box' : 'header-layout'}>
      <div className="header-content">
        {isStatisticsPage && (
          <Link to={'/'} className="header-back-btn desktop-only">
            <img className="header-back-icon" src={backArrow} alt="logo" />
          </Link>
        )}
        <img className="header-logo" src={logo} alt="logo" />
        <img
          className="header-hamburger tablet-only"
          onClick={openSideNav}
          src={iconHamburger}
          alt="menu"
        />
        <div className="header-nav-items desktop-only">
          <a className="header-nav-item" href="/#about">
            {header.about}
          </a>
          <a className="header-nav-item" href="/#features">
            {header.features}
          </a>
          <a className="header-nav-item" href="/#guidelines">
            {header.guidelines}
          </a>
          <a className="header-nav-item" href="/#footer">
            {header.contact}
          </a>
          {loggedIn ? (
            <img
              className="header-profile"
              onClick={openSideNav}
              src={user?.picture}
              alt="profile"
            />
          ) : (
            <button className="header-nav-login" onClick={handleShow}>
              {header.login}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
