import './UpdateProfileModal.css'

import Modal from 'react-modal'
import { useModalContext } from 'Components/ModalContext'
import iconClose from 'assets/images/icon_close.svg'
import iconArrow from 'assets/images/icon_arrow_gray.svg'
import { usePostRequest } from 'hooks/useFetch'
import { useEffect, useRef, useState } from 'react'
import { getToken, getUser, setUser } from 'utils/sessionUtils'
import validator from 'validator'
import { countryCodes } from 'data/countryCodes'
import { updateProfileUrl } from 'data/urls'
import strings from 'data/strings'

const customStyles = {
  content: {
    width: 'auto',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
    margin: 'auto',
    border: 'none',
    background: 'transparent',
  },
  overlay: {
    backgroundColor: '#00000099',
  },
}

const validatePhoneNumber = (number) => {
  const isValidPhoneNumber = validator.isMobilePhone(number)

  return isValidPhoneNumber
}

const UpdateProfileModal = (props) => {
  Modal.setAppElement('#root')

  const { profile } = strings
  const { hideModal } = useModalContext()
  const [showCountryCodes, setShowCountryCodes] = useState(false)
  const [code, setCode] = useState('+91')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [error, setError] = useState()
  const userNameRef = useRef()
  const emailRef = useRef()

  const [updateProfile, { loading, data, error: postError }] =
    usePostRequest(updateProfileUrl)
  const { name, email } = getUser()

  useEffect(() => {
    if (postError) {
      console.log({ postError })
    } else if (data) {
      setUser({ ...getUser(), phoneNumber: `${code}${phoneNumber}` })
      window.location.reload()
    }
  }, [data, postError])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let error
    if (userNameRef.current.value.length === 0) {
      error = { ...error, userName: profile.nameError }
    }
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailRef.current.value)
    ) {
      error = { ...error, email: profile.emailError }
    }
    if (!validatePhoneNumber(`${code}${phoneNumber}`)) {
      error = { ...error, phone: profile.phoneError }
    }
    if (error) {
      setError(error)
    } else {
      updateProfile({
        header: {
          Authorization: getToken(),
        },
        body: {
          userName: userNameRef.current.value,
          phoneNumber: `${code}${phoneNumber}`,
          email: emailRef.current.value,
        },
      })
    }
  }

  const handleCodeSelect = (country) => {
    setShowCountryCodes(false)
    setCode(country.code)
  }

  const handleInputChange = () => {
    setError(null)
  }

  const handlePhoneInput = (e) => {
    e.preventDefault()
    const value = e.target.value
    if (!isNaN(Number(value))) {
      setPhoneNumber(value)
    }
  }

  const toggleDropDown = () => {
    setShowCountryCodes((show) => !show)
  }

  return (
    <Modal isOpen style={customStyles}>
      <form className="up-layout" onSubmit={handleSubmit}>
        <p className="up-heading">{profile.detail}</p>
        <img
          className="up-icon-close"
          src={iconClose}
          onClick={hideModal}
          alt="close"
        />
        <div className="up-input-row">
          <p className="up-label">{profile.name}</p>
          <input
            className="up-input"
            placeholder="User name"
            ref={userNameRef}
            onChange={handleInputChange}
            value={name}
          />
          {error?.userName && <p className="up-error">{error.userName}</p>}
        </div>
        <div className="up-input-row">
          <p className="up-label">{profile.email}</p>
          <input
            className="up-input"
            placeholder="eg:johndoe@gmail.com"
            ref={emailRef}
            onChange={handleInputChange}
            value={email}
          />
          {error?.email && <p className="up-error">{error.email}</p>}
        </div>
        <div className="up-input-row">
          <p className="up-label">{profile.phone}</p>
          <div className="up-phone-layout">
            <p className="up-country-code" onClick={toggleDropDown}>
              {code}
            </p>
            <img
              src={iconArrow}
              className="up-arrow"
              onClick={toggleDropDown}
              alt="dropdown"
            />
            <div className="up-separator" />
            <input
              className="up-input-phone"
              type="tel"
              placeholder="Phone number..."
              onChange={handlePhoneInput}
              value={phoneNumber}
            />
            {showCountryCodes && (
              <div className="us-country-dropdown">
                {countryCodes.map((country, index) => (
                  <div
                    className="us-country-info"
                    onClick={() => handleCodeSelect(country)}
                    key={index}
                  >
                    <p>{country.name}</p>
                    <p className="us-contry-code">{country.code}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          {error?.phone && <p className="up-error">{error.phone}</p>}
        </div>
        <p className="up-note">{profile.note}</p>
        {loading && (
          <div className="up-loader-layout">
            <div className="up-loader" />
          </div>
        )}
        {!loading && (
          <button className="up-button" type="submit">
            {profile.submit}
          </button>
        )}
      </form>
    </Modal>
  )
}

export default UpdateProfileModal
