import './ErrorInfoModal.css'
import Modal from 'react-modal'
import { useModalContext } from 'Components/ModalContext'
import iconClose from 'assets/images/icon_close.svg'
import iconError from 'assets/images/icon_error.svg'
import { useEffect, useState } from 'react'

const customStyles = {
  content: {
    padding: '0',
    margin: 'auto',
    border: 'none',
    background: 'transparent',
  },
  overlay: {
    backgroundColor: '#00000099',
  },
}
const errorInfo = (reason) => {
  if (reason !== null) {
    return (
      <div>
        <p className="info-modal-desc">{reason}</p>
        <p className="info-modal-desc">
          Kindly refer to the instructions and video criteria before uploading
          the video
        </p>
      </div>
    )
  } else {
    return (
      <p className="info-modal-desc">
        Kindly refer to the instructions and video criteria before uploading the
        video
      </p>
    )
  }
}

const ErrorInfoModal = () => {
  Modal.setAppElement('#root')

  const { hideModal, store } = useModalContext()
  const { message, reason } = store.modalProps
  const [seconds, setSeconds] = useState(10)

  useEffect(() => {
    const myInterval = setInterval(() => {
      setSeconds((seconds) => seconds - 1)
      if (seconds === 1) {
        hideModal()
      }
    }, 1000)

    return () => clearInterval(myInterval)
  }, [hideModal, seconds])

  return (
    <Modal isOpen onRequestClose={hideModal} style={customStyles}>
      <div className="info-modal-layout">
        <img className="info-modal-info" src={iconError} alt="info" />
        <div className="info-modal-text-block">
          <p className="info-modal-text">
            {message || 'There seems to be an issue in the file!!!!'}
          </p>
          {errorInfo(reason)}
        </div>
        <div className="info-modal-close-layout">
          <div className="info-modal-timer-layout">
            <p className="info-modal-closing">Closing in</p>
            <p className="info-modal-time-left">{seconds} secs</p>
          </div>
          <img
            className="info-modal-icon-close"
            src={iconClose}
            onClick={hideModal}
            alt="close"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ErrorInfoModal
