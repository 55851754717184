import Header from 'Components/Header'
import SideNavigation from 'Components/SideNavigation'
import { useState } from 'react'

const Layout = (props) => {
  const [showSideNav, setShowSideNav] = useState(false)

  const openSideNav = () => {
    setShowSideNav(true)
  }

  const closeSideNav = () => {
    setShowSideNav(false)
  }

  return (
    <>
      <SideNavigation visible={showSideNav} onClose={closeSideNav} />
      <Header openSideNav={openSideNav} />
      {props.children}
    </>
  )
}

export default Layout
