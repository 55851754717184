import InfoIcon from 'assets/images/info.svg'
import 'Components/PlayerFrameSelection/PlayerFrameSelection.css'
import { useEffect, useState } from 'react'
import { isLoggedIn, getToken } from 'utils/sessionUtils'
import { useNavigate } from 'react-router-dom'
import { Button, Checkbox, Input, Col, Row, Modal } from 'antd'
import { usePutRequest, usePostRequest } from 'hooks/useFetch'
import { updatePlayerNamesUrl, updatePlayerFramesUrl } from 'data/urls'
import { toast } from 'react-toastify'
import { ExclamationCircleFilled } from '@ant-design/icons'
const { confirm } = Modal

const PlayerFrameSelection = ({
  playersName,
  playersNameUpdate,
  playerFrames,
  selectedSessionId,
  playerFrameSubmission,
}) => {
  const [selectedFramesId, setSelectedFramesId] = useState({
    far: [],
    near: [],
    submit: false,
  })
  const [errorMsg, setErrorMsg] = useState()
  const [playerNames, setPlayerNames] = useState({
    player1: playersName.playerOne,
    player2: playersName.playerTwo,
  })
  const loggedIn = isLoggedIn()
  const navigate = useNavigate()
  const [putVideoInfo, { data: putData }] = usePutRequest(updatePlayerNamesUrl)

  const [postVideoInfo, { data: postData }] = usePostRequest(
    updatePlayerFramesUrl
  )

  useEffect(() => {
    if (!loggedIn) {
      console.log('need to login !!!!')
      navigate('/')
    }

    setSelectedFramesId({
      far: playerFrames.selectedFar,
      near: playerFrames.selectedNear,
      submit: playerFrames.submit,
    })
    setPlayerNames({
      player1: playersName.playerOne,
      player2: playersName.playerTwo,
    })
  }, [playerFrames.far, playerFrames.near])

  const frameSelection = (id, frameType) => {
    if (selectedFramesId[frameType].includes(id)) {
      const framesId = selectedFramesId[frameType].filter((item) => item !== id)
      setSelectedFramesId((prev) => ({ ...prev, [frameType]: framesId }))
    } else {
      setSelectedFramesId((prev) => ({
        ...prev,
        [frameType]: [...prev[frameType], id],
      }))
    }
  }

  const allFrameSelection = (checked, framesList, frameType) => {
    const framesListId = Object.values(framesList).map((d) => d.frameId)
    if (checked === true) {
      setSelectedFramesId((prev) => ({ ...prev, [frameType]: framesListId }))
    } else {
      const framesId = selectedFramesId[frameType].filter(
        (item) => !framesListId.includes(item)
      )
      setSelectedFramesId((prev) => ({ ...prev, [frameType]: framesId }))
    }
  }

  const playerInputUpdate = (event) => {
    setPlayerNames((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }))
    setErrorMsg((prev) => ({ ...prev, [event.target.name]: null }))
  }

  const showConfirm = () => {
    confirm({
      title: 'Confirm',
      icon: <ExclamationCircleFilled />,
      content:
        'Please ensure correct player identification as resubmissions are not possible and statistics are generated accordingly.',
      wrapClassName: 'player-identification-confirm-footer',
      onOk: handlePlayerFrames,
      okText: 'Confirm',
      onCancel() {
        console.log('Cancel')
      },
      closable: true,
      centered: true,
      width: '600px',
    })
  }

  const handlePlayerFrames = () => {
    // e.preventDefault()
    let error
    if (!playerNames.player1?.trim()) {
      error = { ...error, player1: 'Enter player 1 name' }
      setPlayerNames((prev) => ({ ...prev, player1: prev.player1.trim() }))
    } else if (playerNames.player1.length < 2) {
      error = { ...error, player1: 'Please provide more than 2 character' }
    }
    if (!playerNames.player2?.trim()) {
      error = { ...error, player2: 'Enter player 2 name' }
      setPlayerNames((prev) => ({ ...prev, player1: prev.player1.trim() }))
    } else if (playerNames.player2.length < 2) {
      error = { ...error, player2: 'Please provide more than 2 character' }
    }
    setErrorMsg(error)
    if (error) {
      return
    }
    const Namebody = {
      sessionId: selectedSessionId,
      playerOneName: playerNames.player1.trim(),
      playerTwoName: playerNames.player2.trim(),
    }
    putVideoInfo({
      header: {
        Authorization: getToken(),
      },
      body: Namebody,
    })

    if (!selectedFramesId?.far?.length > 0 && !selectedFramesId?.near?.length) {
      error = { ...error, far: 'Please select player frame' }
    }
    if (error) {
      return
    }
    const body = {
      ...selectedFramesId,
      sessionId: selectedSessionId,
    }
    postVideoInfo({
      header: {
        Authorization: getToken(),
      },
      body,
    })
  }

  useEffect(() => {
    if (putData) {
      playersNameUpdate(putData)
    }
  }, [putData])

  useEffect(() => {
    if (postData) {
      setSelectedFramesId((prev) => ({ ...prev, submit: true }))
      playerFrameSubmission({
        selectedFar: selectedFramesId.far,
        selectedNear: selectedFramesId.near,
        submit: true,
      })
      toast.success('Selected frames submitted successfully')
    }
  }, [postData])

  const notSelectableMessage = () => {
    toast.info('Player identification as resubmissions are not possible')
  }

  return (
    <div className="player-selection">
      <div className="player-selection-title">
        {!selectedFramesId.submit
          ? 'Select ' +
            (playerNames.player1 || 'Player 1') +
            ' from the frames:'
          : 'Selected ' + (playersName.playerOne || 'Player 1') + ' frames:'}
      </div>

      <form className="player-selection-form" autoComplete="off">
        <Row gutter={[30, 24]} className="">
          <Col className="player-selection-name">
            <div> Player 1</div>
            <div className="player-input-box">
              <Input
                name="player1"
                className={
                  'player-selection-input' + (errorMsg?.player1 ? ' error' : '')
                }
                allowClear
                maxLength={30}
                placeholder="P1"
                value={playerNames.player1}
                onChange={playerInputUpdate}
                disabled={selectedFramesId.submit}
              />
              {errorMsg?.player1 ? (
                <div className="error-msg">{errorMsg.player1}</div>
              ) : null}
            </div>
          </Col>
          <Col className="player-selection-name">
            <div>Player 2</div>
            <div className="player-input-box">
              <Input
                name="player2"
                className={
                  'player-selection-input' + (errorMsg?.player2 ? ' error' : '')
                }
                allowClear
                maxLength={30}
                placeholder="P2"
                value={playerNames.player2}
                onChange={playerInputUpdate}
                disabled={selectedFramesId.submit}
              />
              {errorMsg?.player2 ? (
                <div className="error-msg">{errorMsg.player2}</div>
              ) : null}
            </div>
          </Col>
        </Row>
      </form>

      <>
        <Row justify="space-between" wrap={true} className="player-img-box">
          <Col span={!selectedFramesId.submit ? 22 : 24}>
            <Row gutter={[24, 16]} wrap={true} className="player-frames-row">
              {!selectedFramesId.submit ? (
                <Col span={1} className="player-checkbox">
                  <Checkbox
                    onChange={
                      !selectedFramesId.submit
                        ? (e) =>
                            allFrameSelection(
                              e.target.checked,
                              playerFrames.far,
                              'far'
                            )
                        : null
                    }
                    checked={playerFrames.far.every((frame) =>
                      selectedFramesId.far.includes(frame.frameId)
                    )}
                  />
                </Col>
              ) : null}
              <Col span={!selectedFramesId.submit ? 22 : 24}>
                <Row wrap={true}>
                  {playerFrames.far.map((playerImg, index) => (
                    <Col key={index} className="col-player-frames">
                      <img
                        className={
                          'statistics-player-img' +
                          (selectedFramesId.far.includes(playerImg.frameId)
                            ? ' selected'
                            : '') +
                          (selectedFramesId.submit ? ' disabled' : '')
                        }
                        src={playerImg.imageUrl}
                        alt="player"
                        onClick={
                          !selectedFramesId.submit
                            ? () => frameSelection(playerImg.frameId, 'far')
                            : notSelectableMessage
                        }
                      />
                      <div className="statistics-player-img-name">
                        {selectedFramesId.far.includes(playerImg.frameId)
                          ? playerNames.player1 || 'P1'
                          : null}
                        {!selectedFramesId.far.includes(playerImg.frameId) &&
                        selectedFramesId.submit
                          ? playerNames.player2 || 'P2'
                          : null}
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 16]} wrap={true} className="player-frames-row">
              {!selectedFramesId.submit ? (
                <Col span={1} className="player-checkbox">
                  <Checkbox
                    onChange={
                      !selectedFramesId.submit
                        ? (e) =>
                            allFrameSelection(
                              e.target.checked,
                              playerFrames.near,
                              'near'
                            )
                        : null
                    }
                    checked={playerFrames.near.every((frame) =>
                      selectedFramesId.near.includes(frame.frameId)
                    )}
                  />
                </Col>
              ) : null}
              <Col span={!selectedFramesId.submit ? 22 : 24}>
                <Row wrap={true}>
                  {playerFrames.near.map((playerImg, index) => (
                    <Col key={index} className="col-player-frames">
                      <img
                        className={
                          'statistics-player-img' +
                          (selectedFramesId.near.includes(playerImg.frameId)
                            ? ' selected'
                            : '') +
                          (selectedFramesId.submit ? ' disabled' : '')
                        }
                        src={playerImg.imageUrl}
                        alt="player"
                        onClick={
                          !selectedFramesId.submit
                            ? () => frameSelection(playerImg.frameId, 'near')
                            : notSelectableMessage
                        }
                      />
                      <div className="statistics-player-img-name ellipsis">
                        {selectedFramesId.near.includes(playerImg.frameId)
                          ? playerNames.player1 || 'P1'
                          : null}
                        {!selectedFramesId.near.includes(playerImg.frameId) &&
                        selectedFramesId.submit
                          ? playerNames.player2 || 'P2'
                          : null}
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
          {!selectedFramesId.submit ? (
            <Col sm={{ span: 2 }} span={1} className="player-selection-btn-col">
              <Button
                onClick={showConfirm}
                className="player-selection-btn"
                disabled={
                  selectedFramesId.far.length === 0 &&
                  selectedFramesId.near.length === 0
                }
              >
                Submit
              </Button>
            </Col>
          ) : null}
        </Row>
        <Row className="player-selection-info-box justify-content-center">
          <Col className="player-selection-info">
            <img
              className="player-selection-info-icon"
              src={InfoIcon}
              alt="player"
            />
            <div className="player-selection-content">
              {' '}
              Please ensure correct player identification as resubmissions are
              not possible and statistics are generated accordingly.{' '}
            </div>
          </Col>
        </Row>
      </>
    </div>
  )
}

export default PlayerFrameSelection
