import 'Components/Banner/Banner.css'
import { MODAL_TYPES, useModalContext } from 'Components/ModalContext'
import strings from 'data/strings'
import { isLoggedIn } from 'utils/sessionUtils'

function Banner() {
  const { showModal } = useModalContext()
  const { banner } = strings
  const loggedIn = isLoggedIn()

  const handleShow = () => {
    showModal(MODAL_TYPES.LOGIN_MODAL, {})
  }

  return (
    <div className="banner-bg">
      <div className="banner-contents">
        {/* <p className='banner-heading-one'>Using AI to </p> */}
        <p className="banner-heading-two">{banner.head1}</p>
        <p className="banner-heading-two">{banner.head2}</p>
        <p className="banner-description">
          {banner.subtext1}
          <b>{banner.subtext2}</b>
        </p>
        <div className="banner-buttons">
          {!loggedIn && (
            <button className="banner-button-one" onClick={handleShow}>
              {banner.login}
            </button>
          )}
          {/* <button className='banner-button-two'>Subscribe to us</button> */}
        </div>
      </div>
    </div>
  )
}

export default Banner
