import 'Components/About/About.css'
import centerImage from 'assets/images/about-center-image.svg'
import rightImage from 'assets/images/about-right-image.svg'
import strings from 'data/strings'

function About() {
  const { about } = strings

  return (
    <>
      <div className="about-layout" id="about">
        <div className="about-image-layout">
          <div className="about-left-bg" />
          <img className="about-center-image" src={centerImage} alt="player" />
        </div>
        <div className="about-desktop-only">
          <div className="about-content">
            <p className="about-head">
              {about.title}{' '}
              <span className="about-head-black">{about.jusplay}</span>
            </p>
            <p className="about-para">{about.para1}</p>
            <p className="about-para">{about.para2}</p>
          </div>
        </div>
        <img
          className="about-right-image"
          src={rightImage}
          alt={'tennis-balls'}
        />
      </div>
      <div className="about-tablet-only about-desc">
        <p className="about-head">
          {about.title}{' '}
          <span className="about-head-black">{about.jusplay}</span>
        </p>
        <p className="about-para">{about.para1}</p>
        <p className="about-para">{about.para2}</p>
      </div>
    </>
  )
}

export default About
