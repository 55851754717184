import { MODAL_TYPES, useModalContext } from 'Components/ModalContext'
import strings from 'data/strings'
import { clearStorage, getUser, isLoggedIn } from 'utils/sessionUtils'
import './SideNavigation.css'
import { useNavigate } from 'react-router-dom'

const SideNavigation = ({ onClose, visible }) => {
  const navigate = useNavigate()
  const { header } = strings
  const user = getUser()
  const loggedIn = isLoggedIn()
  const { showModal } = useModalContext()
  const layoutClass = `sidenav-layout ${visible ? 'visible' : 'not-visible'}`

  const handleLogout = () => {
    onClose()
    clearStorage()
    if (window.location.pathname !== '/') {
      navigate('/')
    } else {
      window.location.reload()
    }
  }

  const handleLogin = () => {
    onClose()
    showModal(MODAL_TYPES.LOGIN_MODAL, {})
  }

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  return (
    <div className={layoutClass} onClick={onClose}>
      <div className="sidenav" onClick={stopPropagation}>
        {user?.picture && (
          <img src={user?.picture} className="sidenav-profile" alt="profile" />
        )}
        {user?.name && <p className="sidenav-name">{user?.name}</p>}
        {user?.email && <p className="sidenav-info">{user?.email}</p>}
        {user?.phoneNumber && (
          <p className="sidenav-info">{user?.phoneNumber}</p>
        )}

        <div
          className={
            'sidenav-links' +
            (!(user?.picture && user?.name && user?.email) ? ' no-user' : '')
          }
        >
          <a className="sidenav-item" href="/#about" onClick={onClose}>
            {header.about}
          </a>
          <a className="sidenav-item" href="/#features" onClick={onClose}>
            {header.features}
          </a>
          <a className="sidenav-item" href="/#guidelines" onClick={onClose}>
            {header.guidelines}
          </a>
          <a className="sidenav-item" href="/#footer" onClick={onClose}>
            {header.contact}
          </a>
        </div>
        {loggedIn && (
          <button className="sidenav-button" onClick={handleLogout}>
            {header.logout}
          </button>
        )}
        {!loggedIn && (
          <button className="sidenav-button" onClick={handleLogin}>
            {header.login}
          </button>
        )}
      </div>
    </div>
  )
}

export default SideNavigation
