import iconVideo from 'assets/images/icon_video.svg'
import iconUploadVideo from 'assets/images/icon_upload_video.svg'
import iconNotification from 'assets/images/icon_notification.svg'
import './styles.css'
import strings from 'data/strings';

const UploadInstructions = (props) => {

  const { instruct } = strings
  const { showModal, disabled, showUpload } = props

  return (
    <div className="ui-layout" >
      {showUpload && <button className='ui-button' disabled={disabled} onClick={showModal}>{instruct.upload}</button>}
      <div className='ui-content'>
        <div className="ui-item-main">
          <img className="ui-icon" src={iconVideo} alt="choose video" />
        </div>
        <div className='ui-desc-main'>
          <p className='ui-desc-head-main'>{instruct.criteria}</p>
          <p className='ui-desc-content-main'>{instruct.ticks}</p>
        </div>

        <div className="ui-number">1</div>
        <div className='ui-desc-sub'>
          <p className='ui-desc-head-sub'>{instruct.quality}</p>
          <p className='ui-desc-content-sub'>{instruct.accept}</p>
        </div>

        <div className="ui-number">2</div>
        <div className='ui-desc-sub'>
          <p className='ui-desc-head-sub'>{instruct.size}</p>
          <p className='ui-desc-content-sub'>{instruct.limit}</p>
        </div>

        <div className="ui-number">3</div>
        <div className='ui-desc-sub'>
          <p className='ui-desc-head-sub'>{instruct.format}</p>
          <p className='ui-desc-content-sub'>{instruct.type}</p>
        </div>

        <div className="ui-number">4</div>
        <div className='ui-desc-sub'>
          <p className='ui-desc-head-sub'>{instruct.record}</p>
          <p className='ui-desc-content-sub'>{instruct.guidelines}</p>
        </div>

        <div className="ui-item-main">
          <img className="ui-icon" src={iconUploadVideo} alt="upload video" />
        </div>
        <div className='ui-desc-main'>
          <p className='ui-desc-head-main'>{instruct.videos}</p>
          <p className='ui-desc-content-main'>{instruct.drive}</p>
        </div>

        <div className="ui-item-main">
          <img className="ui-icon" src={iconNotification} alt="get notified" />
        </div>
        <div className='ui-desc-main'>
          <p className='ui-desc-head-main'>{instruct.notify}</p>
          <p className='ui-desc-content-main'>{instruct.email}</p>
        </div>
      </div>
    </div>
  );
};

export default UploadInstructions