import React, { useState, createContext, useContext } from 'react'
import SignInModal from 'Components/SignInModal'
import UpdateProfileModal from 'Components/UpdateProfileModal'
import ErrorInfoModal from 'Components/ErrorInfoModal'

const MODAL_TYPES = {
  LOGIN_MODAL: 'LOGIN_MODAL',
  UPDATE_PROFILE_MODAL: 'UPDATE_PROFILE_MODAL',
  ERROR_INFO_MODAL: 'ERROR_INFO_MODAL',
}

const MODAL_COMPONENTS = {
  [MODAL_TYPES.LOGIN_MODAL]: SignInModal,
  [MODAL_TYPES.UPDATE_PROFILE_MODAL]: UpdateProfileModal,
  [MODAL_TYPES.ERROR_INFO_MODAL]: ErrorInfoModal,
}

const initalState = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
}

const ModalContext = createContext(initalState)
const useModalContext = () => useContext(ModalContext)

const GlobalModal = ({ children }) => {
  const [store, setStore] = useState()
  const { modalType, modalProps } = store || {}

  const showModal = (modalType, modalProps = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    })
  }

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    })
  }

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType]
    if (!modalType || !ModalComponent) {
      return null
    }

    return <ModalComponent id="global-modal" {...modalProps} />
  }

  return (
    <ModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </ModalContext.Provider>
  )
}

export { MODAL_TYPES, useModalContext, GlobalModal }
