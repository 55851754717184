import { Checkbox, Dropdown } from 'antd'
import FilterIcon from 'assets/images/filter.svg'
import { useState } from 'react'

const filterByShotValues = ['FOREHAND', 'BACKHAND', 'SERVICE']
const filterByPositionValues = ['IN', 'OUT', 'NET']

const BallPlayerPositionFilter = ({ PositionsUpdate, player }) => {
  const [filterByShot, setFiletrByShot] = useState(filterByShotValues)
  const [filterByPosition, setFiletrByPosition] = useState(
    filterByPositionValues
  )
  const [filterDropdownView, setFilterDropDownView] = useState(false)

  const filterByShotUpdate = (name, value = null) => {
    if (name === 'All') {
      setFiletrByShot(filterByShotValues)
    } else if (value) {
      setFiletrByShot((prev) => [...prev, name])
    } else if (filterByShot.length > 1) {
      setFiletrByShot((prev) => prev.filter((item) => item !== name))
    }
  }

  const filterByPositionUpdate = (name, value = null) => {
    if (name === 'All') {
      setFiletrByPosition(filterByPositionValues)
    } else if (value) {
      setFiletrByPosition((prev) => [...prev, name])
    } else if (filterByPosition.length > 1) {
      setFiletrByPosition((prev) => prev.filter((item) => item !== name))
    }
  }

  const filterValueClear = () => {
    setFiletrByShot(filterByShotValues)
    setFiletrByPosition(filterByPositionValues)
  }

  const filterDropdownViewUpdate = () => {
    setFilterDropDownView(!filterDropdownView)
  }

  const applyFilter = () => {
    setFilterDropDownView(!filterDropdownView)
    PositionsUpdate(player, filterByShot, filterByPosition)
  }

  const menu = (
    <div className="ball-placement-filter-menus">
      <div className="top-menus-btn">
        <div className="filter-title">Filter</div>
        <a className="clear" onClick={filterValueClear}>
          Clear
        </a>
      </div>
      <div className="filter-menu-items">
        <div className="filter-sub-title">By shot</div>
        <Checkbox
          className="filter-checkbox"
          checked={
            filterByShotValues.length === filterByShot.length &&
            filterByShot.every((val) => filterByShotValues.includes(val))
          }
          onChange={(event) => filterByShotUpdate('All', event.target.checked)}
        >
          All
        </Checkbox>
        <Checkbox
          className="filter-checkbox"
          checked={filterByShot.includes('FOREHAND')}
          disabled={
            filterByShot.length === 1 && filterByShot.includes('FOREHAND')
          }
          onChange={(event) =>
            filterByShotUpdate('FOREHAND', event.target.checked)
          }
        >
          Forehand
        </Checkbox>
        <Checkbox
          className="filter-checkbox"
          checked={filterByShot.includes('BACKHAND')}
          disabled={
            filterByShot.length === 1 && filterByShot.includes('BACKHAND')
          }
          onChange={(event) =>
            filterByShotUpdate('BACKHAND', event.target.checked)
          }
        >
          Backhand
        </Checkbox>
        <Checkbox
          className="filter-checkbox"
          checked={filterByShot.includes('SERVICE')}
          disabled={
            filterByShot.length === 1 && filterByShot.includes('SERVICE')
          }
          onChange={(event) =>
            filterByShotUpdate('SERVICE', event.target.checked)
          }
        >
          Service
        </Checkbox>
      </div>

      <div className="filter-menu-items position">
        <div className="filter-sub-title">By position</div>
        <Checkbox
          className="filter-checkbox"
          checked={
            filterByPositionValues.length === filterByPosition.length &&
            filterByPosition.every((val) =>
              filterByPositionValues.includes(val)
            )
          }
          onChange={(event) =>
            filterByPositionUpdate('All', event.target.checked)
          }
        >
          All
        </Checkbox>
        <Checkbox
          className="filter-checkbox"
          checked={filterByPosition.includes('IN')}
          disabled={
            filterByPosition.length === 1 && filterByPosition.includes('IN')
          }
          onChange={(event) =>
            filterByPositionUpdate('IN', event.target.checked)
          }
        >
          In
        </Checkbox>
        <Checkbox
          className="filter-checkbox"
          checked={filterByPosition.includes('OUT')}
          disabled={
            filterByPosition.length === 1 && filterByPosition.includes('OUT')
          }
          onChange={(event) =>
            filterByPositionUpdate('OUT', event.target.checked)
          }
        >
          Out
        </Checkbox>
        <Checkbox
          className="filter-checkbox"
          checked={filterByPosition.includes('NET')}
          disabled={
            filterByPosition.length === 1 && filterByPosition.includes('NET')
          }
          onChange={(event) =>
            filterByPositionUpdate('NET', event.target.checked)
          }
        >
          Net
        </Checkbox>
      </div>
      <div className="filter-footer">
        <button className="submit-btn" onClick={applyFilter}>
          Apply
        </button>
      </div>
    </div>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      open={filterDropdownView}
      placement={'bottomRight'}
      onOpenChange={filterDropdownViewUpdate}
    >
      <div className="filter-icon" onClick={filterDropdownViewUpdate}>
        <img src={FilterIcon} width={24} height={24} />
      </div>
    </Dropdown>
  )
}

export default BallPlayerPositionFilter
