import './UploadStats.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import VideosDataTable from './dataTable'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { unlockLimitUrl, unlockVideoUrl, statisticsGetUrl } from 'data/urls'
import { getToken } from 'utils/sessionUtils'
import strings from 'data/strings'
import {
  useGetRequest,
  usePostRequest,
  useAjaxFramesGetRequest,
} from 'hooks/useFetch'
import StatsUnlockModal from 'Components/StatsUnlockModal'

const UploadStats = ({ list, progress, updateTableList, abortUpload }) => {
  const { footer } = strings
  const location = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sessionId, setSessionId] = useState({
    selectedId: null,
    frameId: null,
  })
  const [unlockLimit, setUnlockLimit] = useState({
    maxUnlockLimit: 3,
    usedUnlockLimit: 0,
  })
  const [getProfile, { data: limitData }] = useGetRequest(unlockLimitUrl)
  const frameUrl = statisticsGetUrl.replace('{sessionId}', sessionId.frameId)
  const [getframes, { data: frameData }] = useAjaxFramesGetRequest(
    frameUrl,
    location.pathname
  )

  const url = sessionId.selectedId
    ? unlockVideoUrl + '?sessionId=' + sessionId.selectedId
    : unlockVideoUrl
  const [postVideoInfo, { data: postData }] = usePostRequest(url)

  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea')
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy')
    } catch (err) {
      console.error('Unable to copy to clipboard', err)
    }
    document.body.removeChild(textArea)
  }

  const copy = (rowContent) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(rowContent.processedFileUrl)
    } else {
      unsecuredCopyToClipboard(rowContent.processedFileUrl)
    }
    toast.success('File url copied')
  }

  const showModal = (selectedSessionId) => {
    if (unlockLimit.maxUnlockLimit > unlockLimit.usedUnlockLimit) {
      setIsModalOpen(true)
      setSessionId((prev) => ({ ...prev, selectedId: selectedSessionId }))
    } else {
      toast.error(
        'Your available free unlocks for video statistics have been depleted. For continued access, please reach out to us at ' +
          footer.email +
          '.'
      )
    }
  }

  useEffect(() => {
    if (postData && postData.id) {
      toast.success(
        'Unlocked statistics for this video! Your comprehensive insights will be available in a few hours'
      )
      const tableData = list.map((val, idx) =>
        val.id === postData.id ? { ...postData, isFramesGenerated: false } : val
      )
      updateTableList(tableData)
      setUnlockLimit((prev) => ({
        ...prev,
        usedUnlockLimit: prev.usedUnlockLimit + 1,
      }))
    } else if (postData && postData.message) {
      toast.error(postData.message)
    }
  }, [postData])

  useEffect(() => {
    const framesIdData = list.filter(
      (video) => video.isFramesGenerated === false && video.isUnlocked
    )

    if (framesIdData.length > 0) {
      setSessionId((prev) => ({ ...prev, frameId: framesIdData[0].id }))
    }
  }, [list])

  useEffect(() => {
    if (sessionId.frameId) {
      // call ajax api for video frames check
      getframes({
        header: {
          Authorization: getToken(),
        },
      })
    }
  }, [sessionId.frameId])

  useEffect(() => {
    if (frameData && frameData.selectedSessionId) {
      const tableData = list.map((val, idx) =>
        val.id === frameData.selectedSessionId
          ? { ...val, isFramesGenerated: true }
          : val
      )
      updateTableList(tableData)
    }
  }, [frameData])

  useEffect(() => {
    getProfile({
      header: {
        Authorization: getToken(),
      },
    })
  }, [getProfile])

  useEffect(() => {
    if (limitData) {
      setUnlockLimit({
        maxUnlockLimit: limitData.maxUnlockLimit,
        usedUnlockLimit: limitData.usedUnlockLimit,
      })
    }
  }, [limitData])

  const handleOk = () => {
    setIsModalOpen(false)

    postVideoInfo({
      header: {
        Authorization: getToken(),
      },
    })
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className="stats-layout">
      <StatsUnlockModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        unlockLimit={unlockLimit}
      />
      <div className="stats-title-box">
        <div className="stats-title">My Dashboard</div>
        <Link to={'/statistics'} className="header-back-btn">
          <button
            className="view-statistics-btn"
            disabled={!unlockLimit.usedUnlockLimit > 0}
          >
            View Statistics
          </button>
        </Link>
      </div>
      <div className="stats-table-box">
        <VideosDataTable
          data={list}
          abortUpload={abortUpload}
          copy={copy}
          progress={progress}
          showModal={showModal}
          unlockLimit={unlockLimit}
        />
      </div>
    </div>
  )
}

export default UploadStats
