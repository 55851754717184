import iconUpload from 'assets/images/icon_upload.svg'
import { MODAL_TYPES, useModalContext } from 'Components/ModalContext'
import './styles.css'
import Dropzone from 'react-dropzone'
import { useRef } from 'react'
import moment from 'moment'
import axios from 'axios'
import strings from 'data/strings'
import { getToken } from 'utils/sessionUtils'
const SelectFile = ({ onSubmit }) => {
  const { selectfile } = strings

  const fileSelectHandler = async (acceptedFiles) => {
    const allowedFormats = ['mp4', 'mov', 'avi', 'mkv']
    const file = acceptedFiles[0]
    const format = file.path.split('.').pop()
    let error = ''
    if (!allowedFormats.includes(format)) {
      error = 'File format is invalid, Please recheck the file format'
    }
    if (file.size > 5000000000) {
      error =
        'File size must be less than or equal to 5GB. Please check your file'
    }
    if (acceptedFiles.length > 1) {
      error = 'Please select only one video file'
    }
    if (error) {
      showModal(MODAL_TYPES.ERROR_INFO_MODAL, {
        reason: error,
      })

      return
    }
    const data = {
      name: selectfile.progress,
      sub: selectfile.note,
      uploadDate: moment(new Date()).format('DD MMM YYYY'),
      status: selectfile.uploading,
      file,
      downloadLink: selectfile.soon,
      isDriveLink: false,
    }
    onSubmit(data)
  }

  const { showModal } = useModalContext()
  const inputRef = useRef()

  const handleUpload = async () => {
    const fileUrl = inputRef.current.value
    if (fileUrl === '') {
      return
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/session/check-video`,
        {
          fileUrl,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
          },
        }
      )
      .then((response) => {
        console.log(response.data)
        if (response.data.response) {
          const data = {
            name: selectfile.progress,
            sub: selectfile.note,
            uploadDate: moment(new Date()).format('DD MMM YYYY'),
            status: selectfile.uploading,
            downloadLink: selectfile.soon,
            isDriveLink: true,
            fileUrl,
          }
          onSubmit(data)
        } else {
          showModal(MODAL_TYPES.ERROR_INFO_MODAL, {
            reason: response.data.message,
          })
        }
      })
  }

  return (
    <Dropzone onDrop={fileSelectHandler}>
      {({ getRootProps, getInputProps }) => (
        <div
          className="sf-dialog"
          {...getRootProps()}
          onClick={(e) => e.stopPropagation()}
        >
          <input {...getInputProps()} />
          <img className="sf-icon" src={iconUpload} alt="upload" />
          <p className="sf-label">
            {selectfile.drop}{' '}
            <span className="sf-select">{selectfile.files}</span>
          </p>
          <button className="sf-button" {...getRootProps()}>
            {'Select file'}
          </button>
          <p className="sf-instruction-head">{selectfile.instruct1}</p>
          <p className="sf-instruction">{selectfile.ensure}</p>
          <div className="sf-layout-or">
            <div className="sf-line" />
            <p className="sf-label-or">{selectfile.or}</p>
            <div className="sf-line" />
          </div>
          <p className="sf-instruction-link">{selectfile.link} </p>
          <div className="sf-drive-input">
            <input className="sf-input" ref={inputRef} />
            <button className="sf-send" onClick={handleUpload}>
              {selectfile.send}
            </button>
          </div>
          <p className="sf-label align-left">{selectfile.instruct2}</p>
          <div className="sf-instruction-box align-left">
            <p className="sf-instruction-item">{selectfile.single}</p>
            <p className="sf-instruction-item">{selectfile.grant}</p>
          </div>
        </div>
      )}
    </Dropzone>
  )
}

export default SelectFile
