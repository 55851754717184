import 'Components/Home/Home.css'
import Banner from 'Components/Banner'
import About from 'Components/About'
import OurFeatures from 'Components/OurFeatures'
import ProcessGuide from 'Components/ProcessGuide'
import Footer from 'Components/Footer'
import Upload from 'Components/Upload'
import { isLoggedIn } from 'utils/sessionUtils'
import Layout from 'Components/Layout'

const Home = () => {
  const loggedIn = isLoggedIn()

  return (
    <Layout>
      <div className="home">
        {loggedIn && <Upload />}
        {!loggedIn && <Banner />}
        <About />
        <OurFeatures />
        <ProcessGuide />
        <Footer />
      </div>
    </Layout>
  )
}

export default Home
