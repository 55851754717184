import 'Components/VideoFilterView/VideoFilterView.css'
import { Button, Col, Row, Select } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useGetRequest } from 'hooks/useFetch'
import { videoDataGetUrl } from 'data/urls'
import { getToken } from 'utils/sessionUtils'

const VideoFilterView = ({ playersName, selectedVideoData }) => {
  const aud = useRef(null)
  const [videoFilter, setVideoFilter] = useState({
    player: 'P1',
    rally: '0',
    shotType: 'ALL',
    inOut: 'ALL',
    isPlaying: false,
  })
  const [videoData, setVideoData] = useState({
    url: selectedVideoData.url || null,
    timestamps: [],
  })

  const url =
    videoDataGetUrl.replace('{sessionId}', selectedVideoData.id).toString() +
    '?player=' +
    videoFilter.player +
    '&rally=' +
    videoFilter.rally +
    (videoFilter.shotType !== 'ALL'
      ? '&strokeType=' + videoFilter.shotType
      : '') +
    (videoFilter.inOut !== 'ALL' ? '&ballEventType=' + videoFilter.inOut : '')
  const [getVideoData, { data, error }] = useGetRequest(url)

  const videoFilterUpdate = (name, value) => {
    setVideoFilter((prev) => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (
      aud.current &&
      videoData &&
      videoData.timestamps &&
      videoData.timestamps.length > 0
    ) {
      const markerBar = document.getElementById('marker-bar')
      // console.log('marker', videoData.timestamps)
      console.log('marker')
      videoData.timestamps.forEach(function (position) {
        if (position.startTimestamp <= aud.current.duration) {
          const left =
            (position.startTimestamp / aud.current.duration) * 100 + '%'

          const marker = document.createElement('div')
          marker.classList.add('marker')
          marker.id = 'player-video-marker'
          marker.style.left = left
          marker.style.width = (aud.current.duration / 100) * 2 + 'px'

          markerBar.appendChild(marker)
        }
      })

      aud.current.currentTime =
        videoData.timestamps.length > 0
          ? videoData.timestamps[0].startTimestamp
          : 0
      aud.current.addEventListener('timeupdate', MyFunction)

      function MyFunction() {
        videoData.timestamps.forEach(function (timestamp, index) {
          const startTimestamp =
            index + 1 < videoData.timestamps.length
              ? videoData.timestamps[index + 1].startTimestamp
              : 0
          if (
            aud.current.currentTime > timestamp.endTimestamp &&
            aud.current.currentTime < startTimestamp
          ) {
            aud.current.currentTime = startTimestamp
          }
        })

        if (
          aud.current.currentTime >
          videoData.timestamps[videoData.timestamps.length - 1].endTimestamp
        ) {
          aud.current.pause()
          aud.current.currentTime =
            videoData.timestamps.length > 0
              ? videoData.timestamps[0].startTimestamp
              : 0
        }
      }
    }
  }, [videoData.timestamps])

  const playVideo = () => {
    setVideoFilter((prev) => ({ ...prev, isPlaying: true }))
    const mark = document.querySelectorAll('.marker')
    if (mark.length > 0) {
      mark.forEach((el) => el.remove())
    }

    getVideoData({
      header: {
        Authorization: getToken(),
      },
    })
  }

  useEffect(() => {
    getVideoData({
      header: {
        Authorization: getToken(),
      },
    })

    document.querySelectorAll('.marker').forEach((el) => el.remove())
    setVideoFilter((prev) => ({
      ...prev,
      player: 'P1',
      rally: '0',
      shotType: 'ALL',
      inOut: 'ALL',
    }))
    setVideoData((prev) => ({ ...prev, timestamps: [] }))
  }, [selectedVideoData.id])

  useEffect(() => {
    if (data && data.data) {
      // document.querySelectorAll('.marker').forEach((el) => el.remove())
      setVideoData({
        url: data.processedFileUrl,
        timestamps: data.data,
      })

      if (videoFilter.isPlaying) {
        aud.current.play()
      }
    }
  }, [data, error])

  return (
    <div className="video-filter-view-section">
      <div className="video-filter-title">Videos</div>
      <Row justify="space-between" className="video-filters">
        <Col className="video-filter-box">
          <Row>
            <Col flex={'90px'} className="filter-name">
              Player:
            </Col>
            <Col flex={'200px'}>
              <Select
                className=""
                style={{ minWidth: '150px' }}
                onChange={(value) => videoFilterUpdate('player', value)}
                value={videoFilter.player}
              >
                <Select.Option value="P1">
                  {playersName?.playerOne ? playersName.playerOne : 'P1'}
                </Select.Option>
                <Select.Option value="P2">
                  {playersName?.playerTwo ? playersName.playerTwo : 'P2'}
                </Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col className="video-filter-box">
          <Row>
            <Col flex={'90px'} className="filter-name">
              Rally:
            </Col>
            <Col flex={'200px'}>
              <Select
                className=""
                style={{ minWidth: '150px' }}
                defaultValue="0"
                onChange={(value) => videoFilterUpdate('rally', value)}
                value={videoFilter.rally}
              >
                <Select.Option value="0">All</Select.Option>
                <Select.Option value="1">Top 1</Select.Option>
                <Select.Option value="2">Top 2</Select.Option>
                <Select.Option value="3">Top 3</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col className="video-filter-box">
          <Row>
            <Col flex={'90px'} className="filter-name">
              Shot type:
            </Col>
            <Col flex={'200px'}>
              <Select
                className=""
                style={{ minWidth: '150px' }}
                onChange={(value) => videoFilterUpdate('shotType', value)}
                value={videoFilter.shotType}
              >
                <Select.Option value="ALL">All</Select.Option>
                <Select.Option value="FOREHAND">Forehand</Select.Option>
                <Select.Option value="BACKHAND">Backhand</Select.Option>
                <Select.Option value="SERVICE">Service</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col className="video-filter-box">
          <Row>
            <Col flex={'90px'} className="filter-name">
              In/out:
            </Col>
            <Col flex={'200px'}>
              <Select
                className=""
                style={{ minWidth: '150px' }}
                onChange={(value) => videoFilterUpdate('inOut', value)}
                value={videoFilter.inOut}
              >
                <Select.Option value="ALL">All</Select.Option>
                <Select.Option value="IN">In</Select.Option>
                <Select.Option value="OUT">Out</Select.Option>
                <Select.Option value="NET">Net</Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col className="play-col" flex={'auto'}>
          <Button
            onClick={playVideo}
            className="player-selection-btn"
            disabled={false}
          >
            Play
          </Button>
        </Col>
      </Row>
      <div className="video-filter-view">
        <video
          ref={aud}
          className="video-filter-play"
          controls
          height="100%"
          width="100%"
        >
          <source src={videoData.url} type="video/webm" />

          <source src={videoData.url} type="video/mp4" />
        </video>
        <div className="marker-bar-box">
          <div id="marker-bar" className="marker-bar">
            {''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoFilterView
