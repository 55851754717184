import Modal from 'react-modal'
import SelectFile from 'Components/SelectFile'
import iconClose from 'assets/images/icon_close.svg'
import './styles.css'

const customStyles = {
  content: {
    height: '100%',
    display: 'flex',
    padding: '0',
    margin: 'auto',
    border: 'none',
    background: 'transparent',
    inset: 0,
  },
  overlay: {
    backgroundColor: '#00000099',
  },
}

const SelectFileModal = ({ isOpen, hideModal, onSubmit }) => {
  Modal.setAppElement('#root')

  return (
    <Modal isOpen={isOpen} onRequestClose={hideModal} style={customStyles}>
      <div className="sfm-layout">
        <img
          className="sfm-icon-close"
          src={iconClose}
          onClick={hideModal}
          alt="close"
        />
        <SelectFile onSubmit={onSubmit} />
      </div>
    </Modal>
  )
}

export default SelectFileModal
