import moment from 'moment'

export const getTime = (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60
  const hours = Math.floor(time / 3600)
  if (hours) {
    return hours + ' hrs ' + minutes + ' min'
  } else {
    return minutes + ' min ' + seconds + ' sec'
  }
}

export const dateFormat = (createdAt) => moment(createdAt).format('DD MMM YYYY')
