import 'Components/Upload/styles.css'
import SelectFile from 'Components/SelectFile'
import SelectFileModal from 'Components/SelectFileModal'
import VideoInfoModal from 'Components/VideoInfoModal'
import UploadInstructions from 'Components/UploadInstructions'
import UploadStats from 'Components/UploadStats'
import { useGetRequest, usePostRequest } from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { getToken, getUser } from 'utils/sessionUtils'
import { dashboardUrl, videoUploadUrl } from 'data/urls'
import { Upload } from '@aws-sdk/lib-storage'
import { S3Client } from '@aws-sdk/client-s3'

const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET
const REGION = process.env.REACT_APP_AWS_REGION

const UploadComp = () => {
  const [showSelectFileModal, setSelectFileModal] = useState(false)
  const [showInfoModal, setInfoModal] = useState(false)
  const [fileLocation, setFileLocation] = useState()
  const [isDriveLink, setIsDriveLink] = useState(false)
  const [uploadsList, setUploadsList] = useState([])
  const [progress, setProgress] = useState(0)
  const { uuid } = getUser()
  const disableUpload = progress > 0

  const url = dashboardUrl.replace('{uuid}', uuid)
  const [getProfile, { data, loading, error }] = useGetRequest(url)
  const uploadUrl = videoUploadUrl.replace('{uuid}', uuid)
  const [
    postVideoInfo,
    { data: postData, loading: postLoading, error: postError },
  ] = usePostRequest(uploadUrl)

  const reload = () => {
    window.location.reload()
  }

  useEffect(() => {
    getProfile({
      header: {
        Authorization: getToken(),
      },
    })
  }, [getProfile])

  useEffect(() => {
    if (error) {
      console.log({ error })
    } else if (data) {
      setUploadsList(data || [])
    }
  }, [data, error])

  useEffect(() => {
    if (postError) {
      console.log({ postError })
    } else if (postData) {
      reload()
    }
  }, [postData, postError])

  const showSelectFile = !uploadsList || uploadsList?.length === 0

  const showModal = () => {
    setSelectFileModal(true)
  }

  const hideModal = () => {
    setSelectFileModal(false)
  }

  const hideInfoModal = () => {
    setInfoModal(false)
  }

  const updateList = (item) => {
    if (item.isDriveLink) {
      setFileLocation(item.fileUrl)
      setIsDriveLink(true)
      setInfoModal(true)
    } else {
      setUploadsList((list) => {
        return [item, ...list]
      })
      hideModal()
      uploadFile(item.file)
    }
  }

  const updateInfo = (formData) => {
    const body = {
      fileUrl: fileLocation,
      bucket: S3_BUCKET,
      isDriveLink,
      ...formData,
    }

    if (!isDriveLink) {
      body.createdAt = new Date().getTime()
      body.fileName = uploadsList[0].file.path
    }

    postVideoInfo({
      header: {
        Authorization: getToken(),
      },
      body,
    })
  }

  const uploadFile = async (file) => {
    const target = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name,
    }
    try {
      const upload = new Upload({
        client: new S3Client({
          region: REGION,
          credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
          },
        }),
        partSize: 1024 * 1024 * 5,
        leavePartsOnError: false,
        params: target,
      })

      await upload.on('httpUploadProgress', (progress) => {
        setProgress(Math.round((progress.loaded / progress.total) * 100))
      })

      await upload.done()
      const filePath = `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${file.name}`
      setFileLocation(filePath)
      setIsDriveLink(false)
      setUploadsList((list) => {
        const data = list[0]
        const fileName = data.file.path
        data.fileName = fileName
        data.status = 'Uploaded'
        const array = [...list]
        array[0] = data

        return array
      })
      setInfoModal(true)
    } catch (error) {
      console.log({ error })
    } finally {
      setProgress(0)
    }
  }

  const updateTableList = (videoData) => {
    setUploadsList(videoData)
  }

  return (
    <div className="upload-layout">
      {loading && (
        <div className="upload-loader-layout">
          <div className="upload-loader" />
        </div>
      )}
      {!loading && showSelectFile && <SelectFile onSubmit={updateList} />}
      {!loading && !showSelectFile && (
        <UploadStats
          list={uploadsList}
          progress={progress}
          abortUpload={reload}
          updateTableList={updateTableList}
        />
      )}
      <UploadInstructions
        showModal={showModal}
        disabled={disableUpload}
        showUpload={uploadsList.length > 0}
      />
      <SelectFileModal
        isOpen={showSelectFileModal}
        hideModal={hideModal}
        onSubmit={updateList}
      />
      {showInfoModal && (
        <VideoInfoModal
          hideModal={hideInfoModal}
          onSubmit={updateInfo}
          loading={postLoading}
        />
      )}
    </div>
  )
}

export default UploadComp
