import 'Components/ProcessGuide/ProcessGuide.css'
import guidelineNumberOne from 'assets/images/guide-sl-no-one.png'
import guidelineNumberTwo from 'assets/images/guide-sl-no-two.png'
import guidelineNumberThree from 'assets/images/guide-sl-no-three.png'
import guidelineImageOne from 'assets/images/guide-image-one.png'
import guidelineImageTwo from 'assets/images/guide-image-two.png'
import guidelineImageThree from 'assets/images/guide-image-three.svg'
import strings from 'data/strings'

const GuideDescTwo = () => {
  const { guidelines } = strings

  return (
    <>
      <img className="pg-sl-no-image" src={guidelineNumberTwo} alt={'sl-no'} />
      <div className="pg-desc-layout">
        <p className="pg-item-title">{guidelines.height}</p>
        <div className="pg-item-desc pg-list">
          {guidelines.recording}
          {/* <li>Analyse it</li>
                  <li> Trim it shot wise</li>
                  <li>Study it to generate insights</li> */}
        </div>
      </div>
    </>
  )
}

const ProcessGuide = () => {
  const { guidelines } = strings

  return (
    <div className="pg-layout" id="guidelines">
      <div className="pg-contents">
        <p className="pg-head">
          {guidelines.title}{' '}
          <span className="pg-head-orange">{guidelines.record}</span>
        </p>
        <div className="pg-items">
          <div className="pg-flex-row">
            <img
              className="pg-sl-no-image"
              src={guidelineNumberOne}
              alt={'sl-no'}
            />
            <div className="pg-desc-layout">
              <p className="pg-item-title">{guidelines.choices}</p>
              <ol className="pg-item-desc pg-list">
                <li>{guidelines.choice1}</li>
                <li>{guidelines.choice2}</li>
                <li>{guidelines.choice3}</li>
                {/* <li>Import to our website</li> */}
              </ol>
            </div>
          </div>
          <div className="pg-guide-image-align">
            <img
              className="pg-guide-image"
              src={guidelineImageOne}
              alt={'guide'}
            />
          </div>
          {/* show image on left and description on right for desktop */}
          <div className="pg-desktop-only">
            <img
              className="pg-guide-image"
              src={guidelineImageTwo}
              alt={'guide'}
            />
          </div>
          <div className="pg-flex-row justifyRight pg-desktop-only">
            <GuideDescTwo />
          </div>
          {/* show description on left and image on right for tablet and below */}
          <div className="pg-flex-row pg-tablet-only">
            <GuideDescTwo />
          </div>
          <div className="pg-tablet-only pg-guide-image-align">
            <img
              className="pg-guide-image"
              src={guidelineImageTwo}
              alt={'guide'}
            />
          </div>
          <div className="pg-flex-row">
            <img
              className="pg-sl-no-image"
              src={guidelineNumberThree}
              alt={'sl-no'}
            />
            <div className="pg-desc-layout">
              <p className="pg-item-title">{guidelines.captureTitle}</p>
              <div className="pg-item-desc">{guidelines.captureContent}</div>
              {/* <ul className='pg-item-desc pg-list'>
                <li>session-over-session comparisons</li>
                <li>smart insights</li>
                <li>rally trimming</li>
                <li>automated highlights</li>
              </ul> */}
            </div>
          </div>
          <div className="pg-guide-image-align">
            <img
              className="pg-guide-image"
              src={guidelineImageThree}
              alt={'guide'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProcessGuide
