import backhandIn from 'assets/images/backhand_in.svg'
import backhandNet from 'assets/images/backhand_net.svg'
import backhandOut from 'assets/images/backhand_out.svg'
import forehandIn from 'assets/images/forehand_in.svg'
import forehandNet from 'assets/images/forehand_net.svg'
import forehandOut from 'assets/images/forehand_out.svg'
import serviceIn from 'assets/images/service_in.svg'
import serviceNet from 'assets/images/service_net.svg'
import serviceOut from 'assets/images/service_out.svg'
import shoePrintIcon from 'assets/images/shoe_print_icon.svg'
import { useCurrentWidth } from 'hooks/useCurrentWidth'
import { useEffect, useRef, useState } from 'react'

const PlayGround = (props) => {
  const { parentRef, positions } = props
  const [canvas, setCanvas] = useState()
  const [canvasUpdate] = useState(false)
  const canvasRef1 = useRef(null)
  const canvasRef2 = useRef(null)
  const currentWidth = useCurrentWidth()

  useEffect(() => {
    const width = parentRef.current.clientWidth
    const scale = 1665 / width
    const height = (3506 + 3506) / scale
    setCanvas({ width, height })
  }, [currentWidth])

  useEffect(() => {
    if (!canvas) {
      return
    }
    const scale = 1665 / canvas.width

    const canvasRef = canvasRef1.current
    const canvas2Ref = canvasRef2.current
    canvas2Ref.addEventListener('click', clickReporter, false)
    const ctx = canvasRef.getContext('2d')
    const ctxt = canvas2Ref.getContext('2d')

    ctx.reset()
    ctxt.reset()

    // baseline_top
    ctx.moveTo(286 / scale, 561 / scale)
    ctx.lineTo(1379 / scale, 561 / scale)

    // baseline_bottom
    ctx.moveTo(286 / scale, 2935 / scale)
    ctx.lineTo(1379 / scale, 2935 / scale)

    // left_court_line
    ctx.moveTo(286 / scale, 561 / scale)
    ctx.lineTo(286 / scale, 2935 / scale)

    // right_court_line
    ctx.moveTo(1379 / scale, 561 / scale)
    ctx.lineTo(1379 / scale, 2935 / scale)

    // left_inner_line
    ctx.moveTo(423 / scale, 561 / scale)
    ctx.lineTo(423 / scale, 2935 / scale)

    // right_inner_line
    ctx.moveTo(1242 / scale, 561 / scale)
    ctx.lineTo(1242 / scale, 2935 / scale)

    // middle_line
    ctx.moveTo(832 / scale, 1110 / scale)
    ctx.lineTo(832 / scale, 2386 / scale)

    // top_inner_line
    ctx.moveTo(423 / scale, 1110 / scale)
    ctx.lineTo(1242 / scale, 1110 / scale)

    // bottom_inner_line
    ctx.moveTo(423 / scale, 2386 / scale)
    ctx.lineTo(1242 / scale, 2386 / scale)

    // top_extra_part
    ctx.moveTo(832.5 / scale, 561 / scale)
    ctx.lineTo(832.5 / scale, 580 / scale)

    // bottom_extra_part
    ctx.moveTo(832.5 / scale, 2910 / scale)
    ctx.lineTo(832.5 / scale, 2935 / scale)

    ctx.strokeStyle = '#000000'
    ctx.fillStyle = '#FFFFFF'
    ctx.lineWidth = 8.9 / scale
    ctx.fill()
    ctx.stroke()

    positions.forEach((position) => {
      const ballImg = new Image()
      ballImg.src = forehandIn
      if (position.strokeType === 'FOREHAND' && position.ballInOut === 'IN') {
        ballImg.src = forehandIn
      } else if (
        position.strokeType === 'FOREHAND' &&
        position.ballInOut === 'OUT'
      ) {
        ballImg.src = forehandOut
      } else if (
        position.strokeType === 'FOREHAND' &&
        position.ballInOut === 'NET'
      ) {
        ballImg.src = forehandNet
      } else if (
        position.strokeType === 'BACKHAND' &&
        position.ballInOut === 'IN'
      ) {
        ballImg.src = backhandIn
      } else if (
        position.strokeType === 'BACKHAND' &&
        position.ballInOut === 'OUT'
      ) {
        ballImg.src = backhandOut
      } else if (
        position.strokeType === 'BACKHAND' &&
        position.ballInOut === 'NET'
      ) {
        ballImg.src = backhandNet
      } else if (
        position.strokeType === 'SERVICE' &&
        position.ballInOut === 'IN'
      ) {
        ballImg.src = serviceIn
      } else if (
        position.strokeType === 'SERVICE' &&
        position.ballInOut === 'OUT'
      ) {
        ballImg.src = serviceOut
      } else if (
        position.strokeType === 'SERVICE' &&
        position.ballInOut === 'NET'
      ) {
        ballImg.src = serviceNet
      }

      ctx.fillStyle = '#00B3FF'
      ctx.beginPath()
      ctx.fill()

      ballImg.onload = function () {
        ctx.drawImage(
          ballImg,
          (position.xcoOrdinateOfBall - 20) / scale,
          (position.ycoOrdinateOfBall - 20) / scale,
          60 / scale,
          60 / scale
        )
      }

      const img = new Image()
      img.src = shoePrintIcon
      img.id = 'test11'
      ctx.fillStyle = '#00B3FF'
      ctx.beginPath()
      ctx.drawImage(
        img,
        (position.xcoOrdinateOfPlayer - 20) / scale,
        (position.ycoOrdinateOfPlayer - 20) / scale,
        50 / scale,
        60 / scale
      )
      ctx.fill()
    })

    // net
    ctx.beginPath()
    ctx.setLineDash([20, 15])
    ctx.moveTo(0 / scale, 1748 / scale)
    ctx.lineTo(2935 / scale, 1748 / scale)
    ctx.fill()
    ctx.stroke()

    ctx.save()
  }, [canvas, canvasUpdate, positions])

  const clickReporter = (e) => {
    const scale = 1665 / canvas.width
    const x = e.offsetX === undefined ? e.layerX : e.offsetX
    const y = e.offsetY === undefined ? e.layerY : e.offsetY

    const canvasRef = canvasRef2.current
    const ctxt = canvasRef.getContext('2d')

    positions.forEach((position) => {
      if (
        x >= (position.xcoOrdinateOfPlayer - 20) / scale &&
        x <= (position.xcoOrdinateOfPlayer + 20) / scale &&
        y >= (position.ycoOrdinateOfPlayer - 20) / scale &&
        y <= (position.ycoOrdinateOfPlayer + 20) / scale
      ) {
        ctxt.reset()
        ctxt.beginPath()
        ctxt.strokeStyle = '#8F9DBE'
        ctxt.setLineDash([10, 10])
        ctxt.moveTo(
          position.xcoOrdinateOfPlayer / scale,
          position.ycoOrdinateOfPlayer / scale
        )
        ctxt.lineTo(
          position.xcoOrdinateOfBall / scale,
          position.ycoOrdinateOfBall / scale
        )
        ctxt.fill()
        ctxt.lineWidth = 1.9
        ctxt.stroke()
      }
    })
  }

  if (!canvas) {
    return null
  }

  return (
    <>
      <canvas
        ref={canvasRef1}
        className="canvas"
        width={canvas?.width}
        height={canvas?.height / 2}
      />
      <canvas
        ref={canvasRef2}
        className="canvas2"
        width={canvas?.width}
        height={canvas?.height / 2}
      />
    </>
  )
}

export default PlayGround
