const baseUrl = process.env.REACT_APP_BASE_URL

export const loginUrl = `${baseUrl}/public/login`
export const updateProfileUrl = `${baseUrl}/user/update-new-profile`
export const dashboardUrl = `${baseUrl}/session/dashboard/{uuid}`
export const videoUploadUrl = `${baseUrl}/session/video-upload/{uuid}`
export const statisticsGetUrl = `${baseUrl}/player/frames/{sessionId}`
export const updatePlayerFramesUrl = `${baseUrl}/player/frames/`
export const updatePlayerNamesUrl = `${baseUrl}/session/update-player-names`
export const updatePlayerAttributesUrl = `${baseUrl}/analytics/getPlayerAttributes/{sessionId}/`
export const videoDataGetUrl = `${baseUrl}/analytics/getVideoData/{sessionId}`
export const ballPositionStatGetUrl = `${baseUrl}/analytics/player-and-ball-position-stat/{sessionId}`
export const unlockVideoUrl = `${baseUrl}/session/unlock`
export const unlockLimitUrl = `${baseUrl}/session/unlock-limit`
export const statisticsStatusGetUrl = `${baseUrl}/analytics/status/{sessionId}`
